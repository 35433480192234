import {ChargingStation} from '../../models/ChargingStation';
import {
  IConsumptionValue,
  getConsumptionChannelMeasurement,
  getConsumptionChannelCurrent
} from '../../models/ConsumptionValue';

import {getPhasesForType, IHighLevelConfiguration, PhaseType} from '../../models/HighLevelConfiguration';
import {LoadType} from '../../models/Load';
import {getPhaseIndex, getPhaseLabel, Phase} from '../../models/Phase';
import {CalculatedNumberField, ITableField, TimestampFieldWithTimezone} from '../../models/Table';
import {Interval, unitIsPowerInsteadOfConsumption} from '../../models/UsageValue';
import {T} from '../../utils/Internationalization';
import {CreateDateColumn} from '../ElectricityValues/CreateDateColumn';
import {DelayColumn} from '../ElectricityValues/DelayColumn';

import {IRearrangedChargingStationGroupItem} from './RearrangeStationGroupData';
import {ChargingStationConsumptionMode} from './Settings';

export function generateStationColumns(
  timezone: string,
  chargingStation: ChargingStation,
  interval: Interval,
  phaseType: PhaseType,
  isHelpdesk: boolean
): ITableField<IConsumptionValue>[] {
  const sides = chargingStation.getControllersSorted();
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const unit = unitAsPower ? 'kW' : 'kWh';

  const result: ITableField<IConsumptionValue>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('electricityValues.field.timestamp'), timezone)
  ];
  if (isHelpdesk) {
    result.push(new CreateDateColumn(timezone));
    result.push(new DelayColumn());
  }

  const phases = getPhasesForType(phaseType);
  for (let side_ of sides) {
    const side = side_;
    const connector = side.label || 'Side ?';
    const carCharger = side.smartDevice && side.smartDevice.carCharger;
    if (carCharger === undefined) continue;

    for (let phase_ of phases) {
      const phase = phase_;
      const label = T('chargingStationConsumption.seriesForPhase', {
        connector,
        phase: getPhaseLabel(phase)
      });
      const phaseIndex = getPhaseIndex(phase);
      const channelIndex = carCharger.channelIndices[phaseIndex];
      if (channelIndex == null) continue;

      result.push(
        new CalculatedNumberField(
          `side${side.id}phase${phaseIndex}`,
          label,
          item => {
            const result = (item.activePower || [])[channelIndex];
            return result === null ? undefined : result / 1000;
          },
          {unit, digitsAfterComma: 1}
        )
      );
    }
  }

  return result;
}

export function generateStationGroupColumns(
  timezone: string,
  chargingStations: ChargingStation[],
  interval: Interval
): ITableField<IRearrangedChargingStationGroupItem>[] {
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const unit = unitAsPower ? 'kW' : 'kWh';

  const result: ITableField<IRearrangedChargingStationGroupItem>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('electricityValues.field.timestamp'), timezone)
  ];

  for (let i = 0; i < chargingStations.length; i++) {
    const station = chargingStations[i];
    const sides = station.getControllersSorted();

    for (var side_ of sides) {
      const side = side_;
      const connector = side.label || 'Side ?';
      const label = `${station.data.name} ${T('chargingStationConsumption.series', {connector})}`;
      result.push(
        new CalculatedNumberField(
          `side${side.id}`,
          label,
          item => {
            const stationValue = item.data[station.serialNumber];
            if (stationValue === null || stationValue === undefined) {
              return undefined;
            }

            const result = getConsumptionChannelMeasurement(
              stationValue,
              (side.smartDevice && side.smartDevice.carCharger!.channelIndices) || [],
              0.001
            );
            return result === null ? undefined : result;
          },
          {unit, digitsAfterComma: 1}
        )
      );
    }
  }

  return result;
}

export function generateStationGroupCurrentColumns(
  timezone: string,
  interval: Interval,
  mode: ChargingStationConsumptionMode,
  stations: ChargingStation[],
  maximumLoad?: number,
  highLevelConfig?: IHighLevelConfiguration
): ITableField<IRearrangedChargingStationGroupItem>[] {
  const unitAsPower = unitIsPowerInsteadOfConsumption(interval);
  const unit = unitAsPower ? 'A' : 'Ah';

  const consumptionChannels: number[][] = [[], [], []];
  const productionChannels: number[][] = [[], [], []];
  const phases = getPhasesForType(highLevelConfig ? highLevelConfig.phaseType : PhaseType.Single);

  if (highLevelConfig) {
    highLevelConfig.measurements.forEach(load => {
      if (load.type === LoadType.Grid) {
        load.actuals.forEach(channel => {
          consumptionChannels[getPhaseIndex(channel.phase)].push(channel.publishIndex);
        });
      } else if (load.type === LoadType.Production) {
        load.actuals.forEach(channel => {
          productionChannels[getPhaseIndex(channel.phase)].push(channel.publishIndex);
        });
      }
    });
  }

  const getConsumptionCurrent = (item: IConsumptionValue, phase: Phase) => {
    return getConsumptionChannelCurrent(item, consumptionChannels[getPhaseIndex(phase)]) || 0;
  };

  const getProductionCurrent = (item: IConsumptionValue, phase: Phase) => {
    return getConsumptionChannelCurrent(item, productionChannels[getPhaseIndex(phase)]) || 0;
  };

  const result: ITableField<IRearrangedChargingStationGroupItem>[] = [
    new TimestampFieldWithTimezone('timestamp', 'timestamp', T('electricityValues.field.timestamp'), timezone)
  ];

  for (var phase_ of phases) {
    const phase = phase_;
    result.push(
      new CalculatedNumberField(
        `solar${getPhaseIndex(phase)}`,
        phases.length === 1
          ? T('chargingStationConsumption.solar')
          : `${T('chargingStationConsumption.solar')} ${getPhaseLabel(phase)}`,
        item => item.parent && getProductionCurrent(item.parent, phase),
        {unit: 'A', digitsAfterComma: 1}
      )
    );
  }

  stations.forEach(station => {
    const sides = station.getControllersSorted();
    for (var side of sides) {
      const connector = side.label || 'Side ?';
      for (var phase_ of phases) {
        const phase = phase_;
        let label = `${station.data.name} ${T('chargingStationConsumption.series', {connector})}`;
        if (phases.length > 1) label += `, ${getPhaseLabel(phase)}`;

        const channelIndex = ((side.smartDevice && side.smartDevice.carCharger!.channelIndices) || [])[
          getPhaseIndex(phase)
        ];
        if (channelIndex === null) continue;

        result.push(
          new CalculatedNumberField(
            `side${side.id}_${getPhaseIndex(phase)}`,
            label,
            item => {
              const stationValue = item.data[station.serialNumber] || null;
              if (stationValue === null) return undefined;

              const value = (stationValue.current || [])[channelIndex];
              return value === null ? undefined : value;
            },
            {unit: 'A', digitsAfterComma: 1}
          )
        );
      }
    }
  });

  if (mode === ChargingStationConsumptionMode.OverloadIncludingConsumption) {
    for (var phase_ of phases) {
      const phase = phase_;
      result.push(
        new CalculatedNumberField(
          `consumption${getPhaseIndex(phase)}`,
          phases.length === 1
            ? T('chargingStationConsumption.grid')
            : `${T('chargingStationConsumption.grid')} ${getPhaseLabel(phase)}`,
          item => {
            if (!item.parent) return undefined;

            return getConsumptionCurrent(item.parent, phase);
          },
          {unit, digitsAfterComma: 1}
        )
      );
    }
    result.push(
      new CalculatedNumberField('maximumLoad', T('chargingStationConsumption.currentLimit'), item => maximumLoad, {
        unit: 'A',
        digitsAfterComma: 1
      })
    );
  } else {
    for (var phase_ of phases) {
      const phase = phase_;
      result.push(
        new CalculatedNumberField(
          `limit${getPhaseIndex(phase)}`,
          phases.length === 1
            ? T('chargingStationConsumption.currentLimit')
            : `${T('chargingStationConsumption.currentLimit')} ${getPhaseLabel(phase)}`,
          item => (maximumLoad || 0) - ((item.parent && getConsumptionCurrent(item.parent, phase)) || 0),
          {unit: 'A', digitsAfterComma: 1}
        )
      );
    }
  }

  return result;
}
