import React from 'react';
import {FormGroup, Label} from 'reactstrap';

import {Button as RsButton, Input} from '../../../components/bootstrap';
import {Icon} from '../../../components/Icon';
import {OrganizationLanguage} from '../../../models/Organization';
import {T} from '../../../utils/Internationalization';
import {TranslationKey} from '../../../utils/TranslationTerms';

import {FieldValidator} from '../../../utils/Validation';

import {OrganizationFieldTranslation} from './EditOrganizationModels';

interface TranslationInputProps {
  translation: OrganizationFieldTranslation;
  onUpdate: (language: OrganizationLanguage, value: string) => void;
  onDelete: (language: OrganizationLanguage) => void;
  validate?: FieldValidator;
  optional: boolean;
}
export default function TranslationInput(props: TranslationInputProps) {
  const {translation, onUpdate, onDelete, validate, optional} = props;
  const error = validate && validate(translation?.value, `language.${translation.language}`, optional);
  const handleUpdate = (e: React.SyntheticEvent<HTMLInputElement>) =>
    onUpdate(translation.language, e.currentTarget.value);
  const handleDelete = () => onDelete(translation.language);

  return (
    <FormGroup>
      <Label>
        {T(`language.${translation.language}` as TranslationKey)} translation{' '}
        <RsButton
          size="sm"
          color="link"
          withoutPadding
          title="Remove translation"
          style={{marginRight: 10}}
          onClick={handleDelete}
        >
          <span className={Icon.SolidTimesCircle} />
        </RsButton>
      </Label>
      <Input type="textarea" value={translation.value} onChange={handleUpdate} invalid={!!error} />
    </FormGroup>
  );
}
