import {IPersistedTableSettings} from '../components/Table';

export interface ICardSettings {
  locationId?: number;
  name?: string;
  initId?: string;
}

export interface ICardSettingsWithTable extends ICardSettings {
  table: IPersistedTableSettings;
}

// serialized in card settings
export const enum CardDisplayType {
  Table = 'table',
  Chart = 'chart',
  Map = 'map',
  Statistics = 'statistics'
}

// serialized in card settings
export const enum HighOrLowLevel {
  High = 'HIGH_LEVEL',
  Low = 'LOW_LEVEL'
}
