import React from 'react';

import Table, {IPersistedTableSettings} from '../../components/Table';
import {KW_DIGITS_AFTER_COMMA} from '../../core/constants';
import {getCTTypeLabel} from '../../models/CTType';

import {getLegacyConnectionTypeName, getLegacyConsumptionTypeName} from '../../models/LegacyChannel';
import {ILocationSummary, isBigConsumer} from '../../models/Location';
import {getPhaseLabel, PHASES} from '../../models/Phase';
import {ITableField, CalculatedStringField, CalculatedNumberField, CalculatedIntegerField} from '../../models/Table';
import {getPowerFactor} from '../../utils/Calculations';
import {T} from '../../utils/Internationalization';

import {ILiveProData} from './Data';

interface ProLowLevelProps {
  location: ILocationSummary;
  data: ILiveProData[];
  tableSettings: IPersistedTableSettings;
  updateTableSettings: (settings: IPersistedTableSettings) => void;
}
export default class ProLowLevel extends React.Component<ProLowLevelProps, {}> {
  static getTableColumns(location: ILocationSummary): ITableField<ILiveProData>[] {
    const bigConsumer = isBigConsumer(location);
    const powerMultiplier = bigConsumer ? 0.001 : 1;
    const powerDigits = bigConsumer ? KW_DIGITS_AFTER_COMMA : 0;
    return [
      new CalculatedIntegerField('index', '#', row => row.index + 1, {
        alwaysVisible: true,
        autoInsert: 'start'
      }),
      new CalculatedStringField('name', T('liveElectricityValues.table.channel'), item => item.channel.name),
      new CalculatedStringField('phaseLabel', T('liveElectricityValues.table.phase'), item =>
        getPhaseLabel(PHASES[item.channel.phase])
      ),
      new CalculatedStringField('cttype', T('liveElectricityValues.table.ctType'), item =>
        getCTTypeLabel(item.channel.cttype)
      ),
      new CalculatedStringField('connection', T('liveElectricityValues.table.connection'), item =>
        getLegacyConnectionTypeName(item.channel.connection)
      ),
      new CalculatedStringField('consumption', T('liveElectricityValues.table.category'), item =>
        getLegacyConsumptionTypeName(item.channel.consumption)
      ),
      new CalculatedNumberField(
        'active',
        'P',
        row => (row.active === undefined ? undefined : row.active * powerMultiplier),
        {
          tooltip: T('liveElectricityValues.tooltip.activePower'),
          unit: bigConsumer ? 'kW' : 'W',
          digitsAfterComma: powerDigits
        }
      ),
      new CalculatedNumberField(
        'reactive',
        'Q',
        row => (row.reactive === undefined ? undefined : row.reactive * powerMultiplier),
        {
          tooltip: T('liveElectricityValues.tooltip.reactivePower'),
          unit: bigConsumer ? 'kvar' : 'var',
          digitsAfterComma: powerDigits
        }
      ),
      new CalculatedNumberField('powerFactor', 'PF', item => getPowerFactor(item.active, item.reactive), {
        tooltip: T('liveElectricityValues.table.powerFactor'),
        digitsAfterComma: 2
      }),
      new CalculatedNumberField(
        'apparent',
        'S',
        row => {
          if (row.active === undefined || row.reactive === undefined) {
            return undefined;
          }

          return Math.sqrt(row.active * row.active + row.reactive * row.reactive) * powerMultiplier;
        },
        {
          title: T('liveElectricityValues.table.apparentPower'),
          unit: bigConsumer ? 'kVA' : 'VA',
          digitsAfterComma: powerDigits
        }
      ),
      new CalculatedNumberField(
        'voltage',
        T('liveElectricityValues.table.voltage'),
        item => {
          // actually phase voltage
          const {phase} = item.channel;
          const {voltages} = item;
          return voltages[phase];
        },
        {digitsAfterComma: 1}
      ),
      new CalculatedStringField('reversed', T('liveElectricityValues.configuration.reversed'), item =>
        T.generic.yesNo(item.channel.reversed)
      )
    ];
  }

  rowKey = (item: ILiveProData) => item.channel.channel;
  render() {
    const {data, tableSettings, updateTableSettings, location} = this.props;

    return (
      <Table
        fields={ProLowLevel.getTableColumns(location)}
        items={data}
        rowKey={this.rowKey}
        noun="channel"
        settings={tableSettings}
        updateSettings={updateTableSettings}
      />
    );
  }
}
