import React from 'react';

import {Modal, ModalBody, ModalHeader} from '../../../components/bootstrap';
import {IPromiseModalProps, usePromiseModal} from '../../../modals/PromiseModal';
import {IOrganization} from '../../../models/Organization';
import {IUser} from '../../../models/User';
import {AppFeature, hasFeature} from '../../../utils/AppParameters';
import {T} from '../../../utils/Internationalization';

interface APIUserModalProps extends IPromiseModalProps<void> {
  user: IUser;
  organization: IOrganization;
  password?: string;
}
export const APIUserModal = (props: APIUserModalProps) => {
  const {user, organization, password} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const handleClose = () => resolve();

  return (
    <Modal isOpen={isOpen} toggle={handleClose} size="md" autoFocus={false}>
      <ModalHeader toggle={handleClose}>{T('viewAPIUser.title')}</ModalHeader>
      <ModalBody>
        <dl className="row">
          <dt className="col-sm-5">{T('viewAPIUser.organization')}</dt>
          <dd className="col-sm-7">{organization.name}</dd>
          {!hasFeature(AppFeature.SocialLogin) && (
            <>
              <dt className="col-sm-5">{T('viewAPIUser.username')}</dt>
              <dd className="col-sm-7">{user.userName || 'N/A'}</dd>
            </>
          )}
          <dt className="col-sm-5">{T('organizationUsers.field.emailAddress')}</dt>
          <dd className="col-sm-7">{user.emailAddress || 'N/A'}</dd>
          {password && (
            <>
              <dt className="col-sm-5">{T('viewAPIUser.password')}</dt>
              <dd className="col-sm-7">{password || 'N/A'}</dd>
            </>
          )}
          <dt className="col-sm-5">{T('viewAPIUser.appName')}</dt>
          <dd className="col-sm-7">{user.apiAccess && user.apiAccess.appName}</dd>
          <dt className="col-sm-5">{T('viewAPIUser.clientId')}</dt>
          <dd className="col-sm-7">{user.apiAccess && user.apiAccess.clientId}</dd>
          <dt className="col-sm-5">{T('viewAPIUser.clientSecret')}</dt>
          <dd className="col-sm-7">{user.apiAccess && user.apiAccess.clientSecret}</dd>
        </dl>
      </ModalBody>
    </Modal>
  );
};
