import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {Card, Form, FormGroup, Col, Row} from 'reactstrap';

import {useAppContext} from '../../app/context';
import {Label, Input, Button} from '../../components/bootstrap';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {SearchableSelectInput} from '../../components/SearchableSelectInput';
import {ISearchField, IMeasuringCase, IOrganization} from '../../models/Organization';
import {None} from '../../utils/Arrays';
import {T, getCurrentLanguage} from '../../utils/Internationalization';
import {classes} from '../../utils/Styles';
import {SIBELGA_CAMPAIGN_REASONS, SiT} from '../custom/SibelgaMeasurementCampaigns/SibelgaModels';

import styles from './index.module.scss';

function getDisplayName(field: ISearchField) {
  if (field.displayName) return field.displayName;
  else if (field.displayNames) {
    return field.displayNames[getCurrentLanguage()] || field.displayNames.en;
  } else return '';
}

export interface FilterFields {
  organizations: IOrganization[];
  fields: ISearchField[];
}

interface FieldInputProps {
  index: number;
  field: ISearchField;
  value: string;
  organizations: IOrganization[];
  onChange: (index: number, value: string) => void;
  disabled: boolean;
}
const FieldInput = (props: FieldInputProps) => {
  const {field} = props;

  return (
    <FormGroup row style={{alignItems: 'center'}}>
      <Label sm={5} style={{marginRight: 0, paddingLeft: 15}}>
        {getDisplayName(field)}
      </Label>
      <Col sm={7}>
        <FieldInputComponent {...props} />
      </Col>
    </FormGroup>
  );
};

const FieldInputComponent = (props: FieldInputProps) => {
  const {field, index, value, organizations, onChange} = props;

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange(index, e.currentTarget.value);
  };

  if (field.name === 'set' && organizations.find(org => org.name.toLowerCase() === 'fluvius')) {
    return <FluviusMeasurementSetInputComponent {...props} />;
  }
  if (field.name === 'set' && organizations.find(org => org.name.toLowerCase() === 'sibelga')) {
    return <SibelgaMeasurementSetInputComponent {...props} />;
  }
  if (field.name === 'reason' && organizations.find(org => org.name.toLowerCase() === 'sibelga')) {
    return <SibelgaReasonInputComponent {...props} />;
  }
  if (field.possibleValues) {
    if (field.possibleValues.values.length > 20) {
      return <LargeSelectInputComponent {...props} />;
    } else {
      return <SmallSelectInputComponent {...props} />;
    }
  } else {
    return <Input value={value} onChange={handleChange} />;
  }
};

function FluviusMeasurementSetInputComponent(props: FieldInputProps) {
  const {value, index, onChange} = props;

  const [sets, setSets] = useState<IMeasuringCase[]>(None);
  const {api} = useAppContext();

  useEffect(() => {
    api.fluvius.getMeasuringCases().then(setSets);
  }, [api]);

  const options = useMemo(
    () =>
      sets.map(set => (
        <option key={set.id} value={set.name}>
          {set.name}
        </option>
      )),
    [sets]
  );

  return (
    <Input type="select" value={value} onChange={e => onChange(index, e.currentTarget.value)} style={{width: '100%'}}>
      <option key="any" value="">
        (Any)
      </option>
      {options}
    </Input>
  );
}

function SibelgaMeasurementSetInputComponent(props: FieldInputProps) {
  const {value, index, onChange} = props;

  const [sets, setSets] = useState<IMeasuringCase[]>(None);
  const {api} = useAppContext();

  useEffect(() => {
    api.sibelga.getMeasuringCases().then(setSets);
  }, [api]);

  const options = useMemo(
    () =>
      sets.map(set => (
        <option key={set.id} value={set.name}>
          {set.name}
        </option>
      )),
    [sets]
  );

  return (
    <Input type="select" value={value} onChange={e => onChange(index, e.currentTarget.value)} style={{width: '100%'}}>
      <option key="any" value="">
        (Any)
      </option>
      {options}
    </Input>
  );
}

function SibelgaReasonInputComponent(props: FieldInputProps) {
  const {value, index, onChange} = props;

  const options = useMemo(
    () =>
      SIBELGA_CAMPAIGN_REASONS.map(reason => (
        <option key={reason.code} value={reason.code}>
          {SiT(reason.name)}
        </option>
      )),
    []
  );

  return (
    <Input type="select" value={value} onChange={e => onChange(index, e.currentTarget.value)} style={{width: '100%'}}>
      <option key="any" value="">
        (Any)
      </option>
      {options}
    </Input>
  );
}

const SmallSelectInputComponent = (props: FieldInputProps) => {
  const {field, index, value, onChange} = props;

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange(index, e.currentTarget.value);
  };

  const options = useMemo(() => {
    return (
      (field.possibleValues &&
        field.possibleValues.values.map(value => (
          <option key={value} value={value}>
            {value}
          </option>
        ))) ||
      []
    );
  }, [field]);

  return (
    <Input type="select" value={value} onChange={handleChange} style={{width: '100%'}}>
      <option key="any" value="">
        (Any)
      </option>
      {options}
    </Input>
  );
};

const LargeSelectInputComponent = (props: FieldInputProps) => {
  const {field, index, value, onChange} = props;
  const options = useMemo(
    () => [
      {value: '', label: '(Any)'},
      ...(field.possibleValues ? field.possibleValues.values.map(value => ({value, label: value})) : [])
    ],
    [field]
  );
  return (
    <SearchableSelectInput
      options={options}
      value={value}
      onChange={useCallback(value => onChange(index, value), [index, onChange])}
      placeholder=""
    />
  );
};

export enum ActiveInactiveFilter {
  All = 'all',
  Active = 'active',
  Inactive = 'inactive'
}

interface LocationFilterProps {
  isOpen: boolean;
  fields: FilterFields;
  onApplyFilter: (
    activeInactive: ActiveInactiveFilter,
    fieldFilters: string[],
    organization: IOrganization | undefined
  ) => void;
  onClose: () => void;
}
export const LocationFilter = (props: LocationFilterProps) => {
  const {isOpen, fields, onApplyFilter, onClose} = props;

  const [activeInactive, setActiveInactive] = useState(ActiveInactiveFilter.All);
  const [fieldValues, setFieldValues] = useState<string[]>([]);
  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const [organization, setOrganization] = useState<IOrganization>();

  useEffect(() => setFieldValues(fields.fields.map(field => '')), [fields]);

  const handleFilterActiveChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    setActiveInactive(e.currentTarget.value as ActiveInactiveFilter);
  };

  const handleFieldValueChanged = (field: number, value: string) => {
    const newFieldFilters = [...fieldValues];
    newFieldFilters[field] = value;
    setFieldValues(newFieldFilters);
  };

  const handleClickedApply = () => {
    onApplyFilter(activeInactive, fieldValues, organization);
  };

  if (!isOpen) return null;

  let fieldInputs: JSX.Element[] = fields.fields.map((field, index) => {
    return (
      <FieldInput
        key={field.name}
        index={index}
        field={field}
        value={fieldValues[index]}
        organizations={fields.organizations}
        onChange={handleFieldValueChanged}
        disabled={organization !== undefined}
      />
    );
  });
  const useWideLayout = fields.fields.length >= 4;
  if (useWideLayout) {
    fieldInputs = [
      <Row key="first">
        {fieldInputs.map(input => (
          <Col key={input.key || ''} sm={6}>
            {input}
          </Col>
        ))}
      </Row>
    ];
  }
  const activeInactiveOptions = [
    <Label key="all" check style={{margin: 0}}>
      <Input
        type="radio"
        name="filterActive"
        value={ActiveInactiveFilter.All}
        checked={activeInactive === ActiveInactiveFilter.All}
        onChange={handleFilterActiveChanged}
      />{' '}
      {T('locations.filterAll')}
    </Label>,
    <Label key="active" check style={{margin: 0}}>
      <Input
        type="radio"
        name="filterActive"
        value={ActiveInactiveFilter.Active}
        checked={activeInactive === ActiveInactiveFilter.Active}
        onChange={handleFilterActiveChanged}
      />{' '}
      {T('locations.filterActive')}
    </Label>,
    <Label key="inactive" check style={{margin: 0}}>
      <Input
        type="radio"
        name="filterActive"
        value={ActiveInactiveFilter.Inactive}
        checked={activeInactive === ActiveInactiveFilter.Inactive}
        onChange={handleFilterActiveChanged}
      />{' '}
      {T('locations.filterInactive')}
    </Label>
  ];
  let activeInactiveInput: JSX.Element[] = [];
  if (useWideLayout) {
    activeInactiveInput = [
      <FormGroup key="input" check>
        <Row>
          {activeInactiveOptions.map((option, index) => (
            <Col key={index} sm={4}>
              {option}
            </Col>
          ))}
        </Row>
      </FormGroup>
    ];
  } else {
    activeInactiveInput = activeInactiveOptions.map(option => <FormGroup check>{option}</FormGroup>);
  }

  return (
    <div className={classes(styles.filterPanelWrapper, useWideLayout ? styles.wide : undefined)}>
      <Card body className={styles.filterPanel}>
        <div style={{float: 'right'}}>
          <Button color="link" withoutPadding title={T('locations.filter.close')} onClick={onClose}>
            <span className="fas fa-times" />
          </Button>
        </div>
        <Form>
          <FormGroup style={{marginBottom: 0}}>
            <Label sm={4} style={{marginTop: 0, marginBottom: 0}}>
              {T('locations.filter.searchFor')}:
            </Label>
            {activeInactiveInput}
          </FormGroup>
          <FormGroup style={{marginBottom: 0}}>
            <FormGroup style={{marginBottom: 0}}>
              <Label sm={4} style={{marginTop: 0, marginBottom: 0}}>
                {T('locations.filter.organization')}:
              </Label>
            </FormGroup>
            <OrganizationInput
              organizations={inputOrganizations.organizations}
              value={organization}
              onChange={setOrganization}
              placeholder={T('locations.filter.organization.all')}
              noneLabel={T('locations.filter.organization.all')}
              onUpdateQuery={updateOrganizationInputQuery}
            />
          </FormGroup>
          <div style={{paddingTop: 10}}>{fieldInputs}</div>
          <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 15}}>
            <Button color="primary" onClick={handleClickedApply}>
              {T('locations.filter.apply')}
            </Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};
