import {useCallback, useMemo, useState} from 'react';
import {NotificationManager} from 'react-notifications';

import {useAppContext} from '../../app/context';
import {Button} from '../../components/bootstrap';
import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {IPersistedTableSettings} from '../../components/Table';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IChargingSession} from '../../models/ChargingStation';
import {Contract} from '../../models/Contract';
import {IOrganization} from '../../models/Organization';
import {isClosed, SplitBillingAgreement} from '../../models/SplitBillingAgreement';
import {isReadOnlyInOrganization} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {useUser} from '../CardUtils';
import {Reload} from '../components/actions';
import {cardViewProps} from '../components/CardView';
import OrderTokensModal from '../PublicChargingTokens/OrderTokensModal';

import {ActivateSplitBillingModal} from './ActivateSplitBillingModal';
import EditSplitBillingAgreementModal from './EditSplitBillingModal';
import Filter from './Filter';
import {SplitBillingCard} from './SplitBillingCard';
import {UpdateAllAgreementsModal} from './UpdateAllAgreementsModal';

interface ISplitBillingCardSettings extends ICardSettings {
  organizationId?: number;
  agreementsTable: IPersistedTableSettings;
  sessionsTable: IPersistedTableSettings;
}

function SplitBilling(props: ICardProps<ISplitBillingCardSettings>) {
  const {fetch, settings, updateSettings} = props;
  const {api} = useAppContext();
  const modals = useModals();
  const me = useUser();

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const readOnly = isReadOnlyInOrganization(me, organization, inputOrganizations.organizations);

  const [agreements, refreshAgreements, setAgreements] = useCardLoader<SplitBillingAgreement[]>(
    async api => {
      if (me.isRegularUser()) {
        return api.getMySplitBillingAgreements().catch(() => []);
      } else if (organizationId) {
        return api.organizations.getSplitBillingAgreements(organizationId).catch(() => []);
      } else return None;
    },
    [organizationId],
    plural('splitBillingAgreement'),
    None
  );
  const [selectedAgreement, setSelectedAgreement] = useState<SplitBillingAgreement>();
  const [sessions, refreshSessions] = useCardLoader<IChargingSession[]>(
    async api => (selectedAgreement ? api.getSplitBillingChargingSessions(selectedAgreement.id) : None),
    [selectedAgreement],
    plural('chargingSession'),
    None
  );

  const [showPending, setShowPending] = useState(true);
  const [showActive, setShowActive] = useState(true);
  const [showClosed, setShowClosed] = useState(true);
  const [addLoading, setAddLoading] = useState(false);
  const [employeeNumber, setEmployeeNumber] = useState('');

  const hasFilter = !showPending || !showActive || !showClosed || employeeNumber !== '';
  const filteredAgreements = useMemo(() => {
    if (!hasFilter) return agreements;

    return agreements.filter(agreement => {
      if (employeeNumber) {
        if (!(agreement.employeeNumber || '').toLocaleLowerCase().includes(employeeNumber.toLocaleLowerCase())) {
          return false;
        }
      }
      if (agreement.confirmed) {
        const closed = isClosed(agreement);
        return closed ? showClosed : showActive;
      } else {
        return showPending;
      }
    });
  }, [hasFilter, agreements, employeeNumber, showClosed, showActive, showPending]);

  const handleClickedAdd = async () => {
    if (!organization) return;

    const contracts = organization.contracts || None;
    const hasContract = contracts.includes(Contract.SplitBilling);
    if (!hasContract) {
      setAddLoading(true);
      const org = await api.organizations.getById(organization.id, true);
      setAddLoading(false);
      modals
        .show(props => (
          <ActivateSplitBillingModal
            organizationId={organization.id}
            instantAvailable={contracts.includes(Contract.SEPA) && org.linkedToOdoo}
            {...props}
          />
        ))
        .then(activated => activated && NotificationManager.success(T('splitBilling.activate.success')));
    } else {
      modals
        .show<boolean>(props => (
          <EditSplitBillingAgreementModal
            organization={organization}
            existingAgreements={agreements}
            canEditAsEmployee={false}
            canEditAsEmployer={true}
            {...props}
          />
        ))
        .then(created => created && refreshAgreements());
    }
  };

  const handleClickedUpdateAll = async () => {
    if (!organizationId) return;

    const result = await modals.show<SplitBillingAgreement[] | undefined>(props => (
      <UpdateAllAgreementsModal organizationId={organizationId} {...props} />
    ));
    if (result) {
      setAgreements(result);
    }
  };

  const refresh = () => {
    refreshAgreements();
    refreshSessions();
  };

  const handleClickedOrder = () => {
    if (!organization) return;

    api.organizations.getById(organization.id).then(organization => {
      const contracts = organization.contracts || None;
      if (contracts.includes(Contract.SplitBilling)) {
        modals.show(props => <OrderTokensModal organizationId={organization.id} {...props} />);
      } else {
        modals
          .show(props => (
            <ActivateSplitBillingModal
              organizationId={organization.id}
              instantAvailable={contracts.includes(Contract.SEPA) && organization.linkedToOdoo}
              {...props}
            />
          ))
          .then(activated => activated && NotificationManager.success(T('splitBilling.activate.success')));
      }
    });
  };

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organizationId: org ? org.id : undefined});
    },
    [updateSettings]
  );

  const handleRemoved = useCallback(
    (agreement: SplitBillingAgreement) => {
      if (selectedAgreement?.id === agreement.id) {
        setSelectedAgreement(undefined);
      }
    },
    [selectedAgreement]
  );

  const filters = (
    <>
      {inputOrganizations.showInput && (
        <OrganizationInput
          name="organization"
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={handleChangeOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      <Reload onReload={refresh} />
      <Filter
        hasFilter={hasFilter}
        showPending={showPending}
        showActive={showActive}
        showClosed={showClosed}
        employeeNumber={employeeNumber}
        setShowPending={setShowPending}
        setShowActive={setShowActive}
        setShowClosed={setShowClosed}
        setEmployeeNumber={setEmployeeNumber}
      />
    </>
  );

  const actions = (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!readOnly && agreements.length > 0 && (
        <Button onClick={handleClickedUpdateAll}>{T('splitBilling.updateAll')}</Button>
      )}
    </>
  );

  return (
    <SplitBillingCard
      readOnly={readOnly}
      agreements={filteredAgreements}
      filters={filters}
      actions={actions}
      onClickedAdd={me.isRegularUser() || organization === undefined ? undefined : handleClickedAdd}
      onClickedOrder={me.isRegularUser() || organization === undefined ? undefined : handleClickedOrder}
      onRemoved={handleRemoved}
      addLoading={addLoading}
      isEmployer={!me.isRegularUser()}
      refreshAgreements={refreshAgreements}
      selectedAgreement={selectedAgreement}
      setSelectedAgreement={setSelectedAgreement}
      sessions={sessions}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
}

const CARD: ICardType<ISplitBillingCardSettings> = {
  type: CardTypeKey.SplitBilling,
  title: 'splitBilling.title',
  description: 'splitBilling.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  width: 4,
  height: 2,
  defaultSettings: {
    agreementsTable: {
      pageSize: 10,
      columns: [
        {name: 'status', visible: true},
        {name: 'rfid', visible: true},
        {name: 'chargingStationSerial', visible: true},
        {name: 'from', visible: true},
        {name: 'firstName', visible: true},
        {name: 'lastName', visible: true},
        {name: 'employeeNumber', visible: true}
      ]
    },
    sessionsTable: {
      pageSize: 10,
      columns: [
        {name: 'from', visible: true},
        {name: 'to', visible: true},
        {name: 'duration', visible: true},
        {name: 'energy', visible: true},
        {name: 'reinbursement', visible: true}
      ]
    }
  },
  locationAware: CardLocationAwareness.Unaware,
  cardClass: SplitBilling
};
export default CARD;
