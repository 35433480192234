import React, {ReactNode} from 'react';

import {FormGroup, Label} from '../bootstrap';

import styles from './RadioGroup.module.scss';

interface RadioGroupProps {
  label: string;
  children: ReactNode;
}
export const RadioGroup = (props: RadioGroupProps) => {
  const {label, children} = props;

  return (
    <FormGroup className={styles.radioGroup}>
      <Label>{label}</Label>
      {children}
    </FormGroup>
  );
};
