export const enum Phase {
  L1 = 'PHASEA',
  L2 = 'PHASEB',
  L3 = 'PHASEC'
}
export const PHASES: Phase[] = [Phase.L1, Phase.L2, Phase.L3];

const PHASE_LABELS: {[key: string]: string} = {
  PHASEA: 'L1',
  PHASEB: 'L2',
  PHASEC: 'L3'
};
export function getPhaseLabel(phase: Phase) {
  return PHASE_LABELS[phase];
}

export function getPhaseIndex(phase: Phase) {
  switch (phase) {
    case Phase.L1:
      return 0;
    case Phase.L2:
      return 1;
    case Phase.L3:
      return 2;
    default:
      return 0;
  }
}

const PhaseVoltageLabels = ['L1-N', 'L2-N', 'L3-N'];
export function getPhaseVoltageLabel(index: number) {
  return PhaseVoltageLabels[index];
}

const LineVoltageLabels = ['L1-L2', 'L2-L3', 'L1-L3'];
export function getLineVoltageLabel(index: number) {
  return LineVoltageLabels[index];
}
