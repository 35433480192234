import {useCallback, useEffect, useState} from 'react';

import {Form} from 'reactstrap';

import {useAppContext} from '../../../app/context';
import {SingleActionModal} from '../../../components/bootstrap';
import {NumberInputGroup, NumberValue} from '../../../components/inputs/NumberInput';
import {RadioGroup} from '../../../components/inputs/RadioGroup';
import {RadioInput} from '../../../components/inputs/RadioInput';
import {SelectInputGroup} from '../../../components/inputs/SelectInput';
import {usePromiseModal, IPromiseModalProps} from '../../../modals/PromiseModal';
import {DynamicTariff, FlatRateTariff, SpotPriceMarket, TariffType} from '../../../models/Tariff';
import {None} from '../../../utils/Arrays';
import {getCurrencySymbol} from '../../../utils/Currency';
import {T} from '../../../utils/Internationalization';
import {useObjectState} from '../../../utils/ObjectState';

interface BaseTariffSettings {
  type: TariffType;
}

export interface FlatRateTariffSettings extends BaseTariffSettings {
  type: TariffType.Flat;
  flat: FlatRateTariff[];
}

export interface DynamicTariffSettings extends BaseTariffSettings {
  type: TariffType.Dynamic;
  dynamic: DynamicTariff;
}

export type TariffSettings = FlatRateTariffSettings | DynamicTariffSettings;

interface FormState {
  market?: SpotPriceMarket;
  cost: NumberValue;
  multiplier: NumberValue;
  taxMultiplier: NumberValue;
}

function getFormState(settings: TariffSettings): FormState {
  return settings.type === TariffType.Dynamic
    ? {
        market: settings.dynamic.market,
        cost: NumberValue.create(settings.dynamic.cost),
        multiplier: NumberValue.create(settings.dynamic.multiplier),
        taxMultiplier: NumberValue.create(settings.dynamic.taxMultiplier)
      }
    : {
        cost: NumberValue.create(0),
        multiplier: NumberValue.create(),
        taxMultiplier: NumberValue.create()
      };
}

interface EditTariffModalProps extends IPromiseModalProps<TariffSettings | undefined> {
  settings: TariffSettings;
  save: (settings: TariffSettings) => Promise<string | undefined>;
}
export const EditTariffModal = (props: EditTariffModalProps) => {
  const {settings, save} = props;
  const [isOpen, resolve] = usePromiseModal(props);
  const {api} = useAppContext();
  const handleClose = () => resolve(undefined);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [tariffType, setTariffType] = useState<TariffType>(settings.type);
  const [markets, setMarkets] = useState<SpotPriceMarket[]>(None);
  const [formState, updateFormState] = useObjectState<FormState>(getFormState(settings));
  useEffect(() => updateFormState(getFormState(settings)), [settings, updateFormState]);

  useEffect(() => {
    api.getSpotPriceMarkets().then(markets => {
      markets.sort((a, b) => a.name.localeCompare(b.name));
      setMarkets(markets);
    });
  }, [api]);

  const setTariffFlat = useCallback((state: boolean) => {
    if (state) {
      setTariffType(TariffType.Flat);
    }
  }, []);
  const setTariffDynamic = useCallback((state: boolean) => {
    if (state) {
      setTariffType(TariffType.Dynamic);
    }
  }, []);

  const handleClickedSave = () => {
    const result: TariffSettings = {...settings};
    result.type = tariffType;
    if (result.type === TariffType.Dynamic) {
      if (!formState.market) return Promise.resolve();

      result.dynamic = {
        market: formState.market,
        cost: formState.cost.numberValue == null ? undefined : formState.cost.numberValue,
        multiplier: formState.multiplier.numberValue == null ? undefined : formState.multiplier.numberValue,
        taxMultiplier: formState.taxMultiplier.numberValue == null ? undefined : formState.taxMultiplier.numberValue
      };
    }

    setLoading(true);
    return save(result)
      .then(errorMessage => {
        if (errorMessage === undefined) {
          resolve(result);
        } else {
          setError(errorMessage);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <SingleActionModal
      isOpen={isOpen}
      onToggle={handleClose}
      size="md"
      action={handleClickedSave}
      title={T('locationConfiguration.tariff.title')}
      loading={loading}
    >
      <Form>
        <RadioGroup label={T('locationConfiguration.tariff.type')}>
          <RadioInput
            label={T('locationConfiguration.tariff.flat')}
            name="rate_flat"
            checked={tariffType === TariffType.Flat}
            value="flat"
            onChange={setTariffFlat}
          />
          <RadioInput
            label={T('locationConfiguration.tariff.dynamic')}
            name="rate_dynamic"
            checked={tariffType === TariffType.Dynamic}
            value="dynamic"
            onChange={setTariffDynamic}
          />
        </RadioGroup>
        {tariffType === TariffType.Flat && <FlatRateConfiguration />}
        {tariffType === TariffType.Dynamic && (
          <DynamicRateConfiguration markets={markets} settings={formState} update={updateFormState} />
        )}
      </Form>
    </SingleActionModal>
  );
};

function FlatRateConfiguration() {
  return <p>{T('locationConfiguration.tariff.flat.appOnly')}</p>;
}

interface DynamicRateConfigurationProps {
  markets: SpotPriceMarket[];
  settings: FormState;
  update: (updates: Partial<FormState>) => void;
}

function DynamicRateConfiguration(props: DynamicRateConfigurationProps) {
  const {markets, settings, update} = props;
  const {market, cost, multiplier, taxMultiplier} = settings;

  const handleMarketSelected = (value: string) => {
    const marketId = parseInt(value);
    const market = markets.find(m => m.id === marketId);
    update({market});
  };

  return (
    <>
      <SelectInputGroup
        name="market"
        label={T('locationConfiguration.tariff.dynamic.market')}
        value={market === undefined ? '' : market.id.toString()}
        onChange={handleMarketSelected}
      >
        <option value="">{T('locationConfiguration.tariff.dynamic.market.select')}</option>
        {markets.map(market => (
          <option key={market.id} value={market.id.toString()}>
            {market.name}
          </option>
        ))}
      </SelectInputGroup>
      <NumberInputGroup
        name="cost"
        label={T('locationConfiguration.tariff.dynamic.cost')}
        info={T('locationConfiguration.tariff.dynamic.cost.info')}
        value={cost}
        onChange={cost => update({cost})}
        suffix={`${getCurrencySymbol(market?.currency || 'EUR')}/kWh`}
        optional
      />
      <h3 style={{marginTop: '1rem'}}>{T('locationConfiguration.tariff.dynamic.multipliers')}</h3>
      <p style={{color: 'gray'}}>{T('locationConfiguration.tariff.dynamic.multiplier.formula')}</p>
      <NumberInputGroup
        name="multiplier"
        label={T('locationConfiguration.tariff.dynamic.multiplier')}
        value={multiplier}
        onChange={multiplier => update({multiplier})}
        optional
      />
      <NumberInputGroup
        name="taxMultiplier"
        label={T('locationConfiguration.tariff.dynamic.taxMultiplier')}
        value={taxMultiplier}
        onChange={taxMultiplier => update({taxMultiplier})}
        optional
      />
    </>
  );
}
