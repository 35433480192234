import React, {CSSProperties} from 'react';

import {classes} from '../../utils/Styles';
import {FormGroup, Input, Label, FormText} from '../bootstrap';

import styles from './index.module.scss';

interface RadioInputProps {
  checked: boolean;
  name: string;
  value: string;
  label: string;
  onChange?: (checked: boolean) => void;
  onClick?: () => void;
  disabled?: boolean;
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  info?: string;
  compact?: boolean;
  wrap?: boolean;
}
export const RadioInput = (props: RadioInputProps) => {
  const {
    checked,
    name,
    value,
    label,
    onChange,
    onClick,
    disabled,
    style,
    labelStyle,
    info,
    compact,
    wrap = false
  } = props;

  const handleChanged = (e: React.SyntheticEvent<HTMLInputElement>) => {
    onChange?.(e.currentTarget.checked);
  };

  return (
    <FormGroup style={style} className={classes(compact && styles.compact, wrap && styles.wrap)}>
      <Label check style={labelStyle} className={styles.label}>
        <Input
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onClick={onClick}
          onChange={handleChanged}
          style={{position: 'inherit'}}
        />
        {info ? (
          <div>
            {label}
            {info && (
              <FormText color="muted" style={{whiteSpace: 'normal'}}>
                {info}
              </FormText>
            )}
          </div>
        ) : (
          label
        )}
      </Label>
    </FormGroup>
  );
};
