import numeral from 'numeral';
import React from 'react';

import {getPhasesForType, PhaseType} from '../../models/HighLevelConfiguration';
import {Phase, getPhaseLabel} from '../../models/Phase';

import {T} from '../../utils/Internationalization';

import {LoadWithChannels} from './Data';
import styles from './Legenda.module.scss';

const getPhaseIndex = (phase: Phase) => {
  switch (phase) {
    case Phase.L1:
      return 0;
    case Phase.L2:
      return 1;
    case Phase.L3:
      return 2;
    default:
      return 0;
  }
};

interface LegendaProps {
  phaseType: PhaseType;
  load: LoadWithChannels;
  currentAngles: (number | null)[];
  currents: (number | null)[];
  voltages: (number | null)[];
}
export const Legenda = (props: LegendaProps) => {
  const sortedChannels = props.load.channels;
  const rows = sortedChannels.map((channel, index) => {
    const channelIndex = channel.channelIndex;
    const {serialNumber, location, labelName} = channel;
    const phaseIndex = getPhaseIndex(channel.phase);
    return (
      <tr key={channel.id}>
        <td>{labelName}</td>
        <td>{`${serialNumber} ${location}`}</td>
        <td>{getPhaseLabel(channel.phase)}</td>
        <td>{numeral(props.currentAngles[channelIndex] || 0).format('0')}</td>
        <td>{numeral(props.currents[channelIndex] || 0).format('0.0')}</td>
        <td>{numeral(props.voltages[phaseIndex] || 0).format('0.0')}</td>
      </tr>
    );
  });
  const phases = getPhasesForType(props.phaseType);
  let lastIndex = 0;
  phases.forEach(phase => {
    let index = sortedChannels.findIndex(channel => channel.phase === phase);
    if (index < 0) {
      rows.splice(
        lastIndex,
        0,
        <tr key={phase}>
          <td />
          <td />
          <td>{getPhaseLabel(phase)}</td>
          <td />
          <td />
          <td>{numeral(props.voltages[getPhaseIndex(phase)] || 0).format('0.0')}</td>
        </tr>
      );
      lastIndex++;
    } else {
      index++;
      while (index < sortedChannels.length && sortedChannels[index].phase === phase) {
        index++;
      }
      lastIndex = index;
    }
  });
  return (
    <table className={styles.legenda} style={{width: 'auto'}}>
      <thead>
        <tr>
          <th data-name="name" />
          <th data-name="location">{T('phasorDisplay.legenda.location')}</th>
          <th data-name="phase">{T('phasorDisplay.legenda.phase')}</th>
          <th data-name="angle">{T('phasorDisplay.legenda.angle')} (°)</th>
          <th data-name="current">{T('phasorDisplay.legenda.current')} [A]</th>
          <th data-name="voltage">{T('phasorDisplay.legenda.voltage')} [V]</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
