/* eslint-disable import/order */
import dayjs from 'dayjs';
import {createBrowserHistory, Location, Action} from 'history';
import numeral from 'numeral';
import React from 'react';
import ReactDOM from 'react-dom';
import {createStore} from 'redux';

import './tailwind.scss';
import './index.css';
import './assets/Fonts.scss';
import 'bootstrap/dist/css/bootstrap.css';
import './rstabs-custom.css';
import './rsforms-custom.css';
import '@fortawesome/fontawesome-pro/css/all.css';
import './assets/fonts/SmappeeIcons/SmappeeIcons.css'; // TODO: why is this not already handled by Fonts.scss above?
import 'react-datepicker/dist/react-datepicker.css';
import './components/Notifications.scss';
import '@nosferatu500/react-sortable-tree/style.css';

import App from './app/App';
import {getAppParameters} from './app/appParameters';
import {IAppContext} from './app/context';
import {router} from './app/router';
import API from './core/api';
import {AutoLanguageRefresher} from './LanguageRefresher';
import {MqttConnector} from './livedata/MqttConnector';
import {AuthUser} from './models/AuthUser';
import {AnyAppAction} from './redux/actions';
import {logout} from './redux/actions/me';
import {IAppState} from './redux/AppState';
import createRootReducer from './redux/reducers';
import {IResolvedRoute} from './routes/Route';
import createFetch from './utils/CreateFetch';
import {updateMeta} from './utils/DOMUtils';
import {Language, setCurrentLanguage} from './utils/Internationalization';
import {autodetectLanguage} from './utils/LanguageDetection';
import {getUser, clearUser} from './utils/LocalUser';

import 'dayjs/locale/nl';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

import 'numeral/locales/nl-be';
import 'numeral/locales/fr';
import 'numeral/locales/it';
import {AppFeature, hasFeature} from './utils/AppParameters';
import {updateUIState} from './redux/actions/uiState';

declare global {
  interface Window {
    google: any;
    numeral: typeof numeral;
    dayjs: typeof dayjs;
  }
}

window.numeral = numeral;
window.dayjs = dayjs;

const customParseFormat = require('dayjs/plugin/customParseFormat');
const duration = require('dayjs/plugin/duration');
const isoWeek = require('dayjs/plugin/isoWeek');
const localizedFormat = require('dayjs/plugin/localizedFormat');
const relativeTime = require('dayjs/plugin/relativeTime');
const timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
const updateLocale = require('dayjs/plugin/updateLocale');
const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);

setCurrentLanguage((localStorage.getItem('language') as Language | null) || autodetectLanguage());

if (window.location.search === '?nocookies') {
  localStorage.setItem('cookies', '{"accepted":true,"allowMarketing":false}');
  const newUrl = window.location.origin + window.location.pathname;
  window.history.replaceState({path: newUrl}, '', newUrl);
}

const appParameters = getAppParameters();
const preloadedState: Partial<IAppState> = {
  me: {
    me: AuthUser.get(getUser()) || AuthUser.anonymous()
  }
};
const store = createStore<IAppState, AnyAppAction, {}, {}>(createRootReducer(), preloadedState as any);
const backend = createFetch(window.fetch, {baseUrl: appParameters.apiUrl});
const history = createBrowserHistory();
const handleAuthenticationFailure = () => {
  clearUser();
  store.dispatch(logout());
  window.location.pathname = '/login';
};
const handleOnMaintenance = () => {
  updateUIState(store, {inMaintenance: true}); // pass through Board stateProps via Redux store
  // 1. When store.inMaintenance === true => invoke a modal on Board level;
  // 2. onClose modal: first make another api call and if this one succeeds, refresh the entire page
};
const context = {
  api: API.create(store, backend, handleAuthenticationFailure, handleOnMaintenance),
  store,
  mqtt: new MqttConnector(),
  history
};
if (preloadedState.me?.me?.refreshToken) {
  context.api.doRefreshLogin(); // make sure a fresh token is used, even if the old one is not yet expired -- so that the latest user language is being used
}

window.onbeforeunload = function (e: any) {
  context.mqtt.closeAll();
};

if (hasFeature(AppFeature.DevTools)) (window as any).dev = context;

const scrollPositionsHistory: {
  [key: string]: {scrollX: number; scrollY: number};
} = {};
if (window.history && 'scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

// Server-side rendering
let onRenderComplete = function initialRenderComplete(route: IResolvedRoute, location: any) {
  const elem = document.getElementById('css');
  if (elem && elem.parentNode) elem.parentNode.removeChild(elem);

  onRenderComplete = function renderComplete(route: IResolvedRoute, location: any) {
    document.title = route.title;
    updateMeta('description', route.description);

    let scrollX = 0;
    let scrollY = 0;
    const pos = scrollPositionsHistory[location.key];
    if (pos) {
      scrollX = pos.scrollX;
      scrollY = pos.scrollY;
    } else {
      const targetHash = location.hash.substr(1);
      if (targetHash) {
        const target = document.getElementById(targetHash);
        if (target) {
          scrollY = window.pageYOffset + target.getBoundingClientRect().top;
        }
      }
    }

    // Restore the scroll position if it was saved into the state
    // or scroll to the given #hash anchor
    // or scroll to top of the page
    window.scrollTo(scrollX, scrollY);

    // Google Analytics tracking. Don't send 'pageview' event after
    // the initial rendering, as it was already sent
    const googleAnalytics = (window as any).ga;
    if (googleAnalytics) {
      googleAnalytics('send', 'pageview', window.location.pathname);
    }
  };
};

// Container
const container = document.getElementById('root');
let currentLocation = history.location;

// Re-render the app when window.location changes
async function onLocationChange(location: Location, action?: Action) {
  // Remember the latest scroll position for the previous location
  if (currentLocation.key !== undefined) {
    scrollPositionsHistory[currentLocation.key] = {
      scrollX: window.pageXOffset,
      scrollY: window.pageYOffset
    };
  }

  // Delete stored scroll position for next page if any
  if (action === 'PUSH' && location.key !== undefined) {
    delete scrollPositionsHistory[location.key];
  }

  // Dispatch intl
  //context.intl = await store.dispatch(getIntl());

  //await store.dispatch(getMe());

  try {
    // Traverses the list of routes in the order they are defined until
    // it finds the first route that matches provided URL path string
    // and whose action method returns anything other than `undefined`.
    const pageContext: IAppContext = {
      ...context,
      pathname: location.pathname,
      query: new URLSearchParams(location.search)
    };
    const route = await router.resolve(pageContext);
    if (!route) return;

    // Change variable only after comparisons
    currentLocation = location;

    if (route.redirect) {
      history.replace(route.redirect);
      return;
    }

    /*appInstance = ReactDOM.hydrate(
      <App context={context} locale='nl-BE'>
        {route.component || <span>Missing component</span>}
      </App>,
      container,
      () => onRenderComplete(route, location),
    );*/

    ReactDOM.render(
      <App context={pageContext}>
        <AutoLanguageRefresher>{route.component || null}</AutoLanguageRefresher>
      </App>,
      container,
      () => onRenderComplete(route, location)
    );
  } catch (error) {
    if (getAppParameters().development) {
      throw error;
    }

    console.error(error);

    // Do a full page reload if error occurs during client-side navigation
    if (action && currentLocation.key === location.key) {
      window.location.reload();
    }
  }
}

let isHistoryObserved = false;
// Handle client-side navigation by using HTML5 History API
// For more information visit https://github.com/mjackson/history#readme
currentLocation = history.location;
if (!isHistoryObserved) {
  isHistoryObserved = true;
  history.listen(state => onLocationChange(state.location));
}
onLocationChange(currentLocation);

window.addEventListener('load', () => {
  if (window.document.body.clientHeight === 0) {
    window.document.body.classList.add('mobilefix');
    window.document.body.style.height = `${window.innerHeight}px`;
  }
});
