import {useBoardContext} from '../../app/boardcontext';
import {useAppContext} from '../../app/context';
import {Button as RsButton} from '../../components/bootstrap';

import CenteredErrorView from '../../components/CenteredErrorView';
import {migrateTableSettings} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {T} from '../../utils/Internationalization';
import {ICardType, CardTypeKey, CardLocationAwareness, CardCategory, ICardProps} from '../CardType';
import {addCardToBoard, useCardChargingStationGroup} from '../CardUtils';
import ChargingStationParentButtonLink from '../ChargingStations/ChargingStationParentButtonLink';
import {cardViewProps, CardView} from '../components/CardView';

type IChargingStationPrivilegesSettings = ICardSettingsWithTable;

const ChargingStationPrivilegesCard = (props: ICardProps<IChargingStationPrivilegesSettings>) => {
  const {settings} = props;

  const {api, store} = useAppContext();
  const chargingGroup = useCardChargingStationGroup(settings);
  const {board} = useBoardContext();

  const titleAddendum = <ChargingStationParentButtonLink location={chargingGroup} />;

  let error: string | undefined;
  if (chargingGroup === undefined) {
    error = T('chargingStationPrivileges.stationGroupRequired');
  }

  const handleClickedSuggestion = (card: CardTypeKey) => {
    if (!board) return;

    addCardToBoard(api, store, board, card);
  };

  return (
    <CardView error={error} titleAddendum={titleAddendum} {...cardViewProps(props)}>
      <CenteredErrorView style={{maxWidth: 500}}>
        {T('chargingStationPrivileges.movedInfo')}
        <br />
        <br />
        <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'center'}}>
          <RsButton onClick={() => handleClickedSuggestion(CardTypeKey.PricingPolicies)}>
            {T('chargingStationPrivileges.movedInfo.addPricingPolicies')}
          </RsButton>
          <RsButton
            onClick={() => handleClickedSuggestion(CardTypeKey.ChargingStationPriorities)}
            style={{marginLeft: '1rem'}}
          >
            {T('chargingStationPrivileges.movedInfo.addPriorities')}
          </RsButton>
        </div>
      </CenteredErrorView>
    </CardView>
  );
};

const DefaultSettings: IChargingStationPrivilegesSettings = {
  table: {
    pageSize: 20,
    columns: [
      {name: 'target', visible: true},
      {name: 'priority', visible: true},
      {name: 'discount', visible: true}
    ]
  }
};

const CARD: ICardType<IChargingStationPrivilegesSettings> = {
  type: CardTypeKey.ChargingStationPrivileges,
  title: 'chargingStationPrivileges.title',
  description: 'chargingStationPrivileges.description',
  locationAware: CardLocationAwareness.Required,
  categories: [CardCategory.EV, CardCategory.CONFIGURATION],
  rights: UserRights.User,
  width: 2,
  height: 2,
  defaultSettings: DefaultSettings,
  upgradeSettings: migrateTableSettings('table', DefaultSettings.table),
  cardClass: ChargingStationPrivilegesCard,
  deprecated: true
};
export default CARD;
