import React, {useState} from 'react';

import {FormGroup, FormFeedback} from 'reactstrap';

import {Label} from '../components/bootstrap/Label';
import API from '../core/api';

import {IUploadedFile} from '../models/UploadedFile';

import {T} from '../utils/Internationalization';

import {Button} from './bootstrap';

import {Icons} from './Icon';

import styles from './ImageInputField.module.scss';

interface ImageInputGroupProps {
  api: API;
  label: string;
  value: string;
  onChange: (value: string) => void;
  optional?: boolean;
}
export const ImageInputGroup = (props: ImageInputGroupProps) => {
  const {label, optional, ...otherProps} = props;
  return (
    <FormGroup>
      <Label>{label}</Label>
      <ImageInputField {...otherProps} />
      {optional && <span style={{color: 'gray'}}>{T('validatedInput.optional')}</span>}
    </FormGroup>
  );
};

interface ImageInputFieldProps {
  api: API;
  value: string;
  onChange: (value: string) => void;
}
export const ImageInputField = (props: ImageInputFieldProps) => {
  const {api, value, onChange} = props;

  if (value) {
    return (
      <div style={{position: 'relative'}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
          }}
        >
          <img style={{height: 75}} src={value} alt="preview" />
        </div>
        <Button
          size="sm"
          color="link"
          withoutPadding
          title="Edit organization"
          style={{position: 'absolute', right: 20, top: 0}}
          onClick={() => onChange('')}
        >
          {Icons.Delete}
        </Button>
      </div>
    );
  } else {
    return (
      <UploadCustomFile
        api={api}
        locationId={0}
        onUploaded={file => onChange(file.fileName)}
        accept=".jpg,.jpeg,.png"
      />
    );
  }
};

interface IUploadCustomFileProps {
  api: API;
  locationId: number;
  onUploaded: (files: IUploadedFile) => void;
  accept?: string;
}
const UploadCustomFile = (props: IUploadCustomFileProps) => {
  const {api, locationId, onUploaded, accept} = props;
  const [error, setError] = useState<string | undefined>(undefined);

  const handleFileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    const file = files && files[0];
    if (!file) return;

    var image = new Image();
    image.onload = () => {
      if (image.width > 200 || image.height > 200) {
        setError('Maximum image size is 200px x 200px');
      } else {
        api.uploadFile(locationId, file).then(files => {
          const file = files[0];
          onUploaded(file);
        });
      }
    };
    image.src = window.URL.createObjectURL(file);
  };

  return (
    <FormGroup className={styles.files}>
      <input type="file" name="file" onChange={handleFileChanged} accept={accept} />
      {error !== undefined && <FormFeedback style={{display: 'block'}}>{error}</FormFeedback>}
    </FormGroup>
  );
};
