import * as CheckboxPrimitive from '@radix-ui/react-checkbox';

import * as React from 'react';

import {cn} from '../../lib/utils';
import {CheckmarkSmall} from '../ui-lib/icons/small';

type CheckboxProps = Omit<ExtendedCheckboxProps, 'onCheckedChange'> & {onCheckedChange?: (checked: boolean) => void};

const Checkbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
  ({onCheckedChange, ...props}, ref) => {
    const handleChange = (checked: boolean | 'indeterminate') => {
      onCheckedChange?.(checked === 'indeterminate' ? false : checked);
    };

    return <ThreeStateCheckbox {...props} ref={ref} onCheckedChange={handleChange} />;
  }
);

interface ExtendedCheckboxProps extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> {
  cbPrefix?: JSX.Element;
  className?: string;
  extra?: JSX.Element;
  extraLabel?: JSX.Element;
  info?: React.ReactNode;
  label?: string | JSX.Element;
  labelClassName?: string;
  testId?: string;
  wrapperClassName?: string;
  readOnly?: boolean;
  wrap?: boolean;
}

const ThreeStateCheckbox = React.forwardRef<React.ElementRef<typeof CheckboxPrimitive.Root>, ExtendedCheckboxProps>(
  (
    {
      cbPrefix,
      className,
      extra,
      extraLabel,
      info,
      label,
      labelClassName,
      testId,
      wrapperClassName,
      readOnly,
      wrap,
      ...props
    },
    ref
  ) => (
    <>
      <div className={cn('checkbox-wrapper tw-flex tw-items-center tw-space-x-2 tw-mb-0', wrapperClassName)}>
        {cbPrefix ?? null}
        <CheckboxPrimitive.Root
          ref={ref}
          className={cn(
            'tw-peer tw-h-4 tw-w-4 tw-shrink-0 tw-rounded tw-border tw-border-border tw-ring-offset-background focus-visible:tw-outline-none focus-visible:tw-ring-0 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-0 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-brandColor data-[state=checked]:tw-text-secondary-foreground data-[state=checked]:tw-border-brandColor',
            className
          )}
          data-testid={testId}
          {...props}
        >
          <CheckboxPrimitive.Indicator
            data-testid={testId}
            className={cn('tw-flex tw-items-center tw-justify-center tw-text-current')}
          >
            <CheckmarkSmall className="tw-h-4 tw-w-4" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        {label && (
          <label
            htmlFor={props.name}
            className={cn(
              'checkbox-label tw-text-base tw-mb-0 tw-text-nowrap tw-leading-none peer-disabled:tw-cursor-not-allowed peer-disabled:tw-opacity-70',
              labelClassName
            )}
            title={typeof label === 'string' ? label : undefined}
          >
            {label}
          </label>
        )}
        {extraLabel ?? null}
        {extra ?? null}
      </div>
      {info && (
        <div className="tw-flex tw-basis-full tw-mb-4">
          <small className="tw-text-xs tw-text-neutral-600 tw-block tw-whitespace-normal">{info}</small>
        </div>
      )}
    </>
  )
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export {Checkbox};
