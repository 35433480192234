import Popper from 'popper.js';
import React, {ReactNode, useMemo} from 'react';
import {Popover as RsPopover} from 'reactstrap';
import {v1 as uuidv1} from 'uuid';

import {classes} from '../../utils/Styles';

import styles from './Popover.module.scss';

interface StickyPopoverProps {
  id?: string;
  isOpen: boolean;
  target: string | HTMLElement;
  className?: string;
  placement?: Popper.Placement;
  children: ReactNode;
}
export const StickyPopover = (props: StickyPopoverProps) => {
  const {id, isOpen, target, className, placement, children} = props;
  const generatedId = useMemo(() => `p${uuidv1()}`, []);
  return (
    <RsPopover
      id={id || generatedId}
      isOpen={isOpen}
      target={target}
      placement={placement}
      container={document.body}
      className={classes(styles.popover, className)}
      modifiers={{preventOverflow: {boundariesElement: document.body}}}
    >
      {children}
    </RsPopover>
  );
};
