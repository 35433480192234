export enum TrackingType {
  Realtime = 'RT_VALUES',
  Vectors = 'VI_FUND_VECTOR_INFO',
  Harmonics = 'POWER_QUALITY',
  CarCharging = '__CARCHARGING',

  None = '__NONE'
}

export interface IInputSensorDailyDeltas {
  sensorId: number;
  dailyDeltaPulseCounters: number[];
  states?: number[]; // 0 = off, 1 = on
}

export interface IPowerMessage {
  serviceLocationId?: number; // locality parent (also on infinity?)
  consumptionPower: number;
  solarPower: number;
  alwaysOn: number;
  froggySensorDailyDeltas: IFroggyDelta[];
  infinityInputSensorDailyDeltas?: IInputSensorDailyDeltas[]; // charging station, infinity, locality parent, P1S1
  channelData?: number[]; // charging station, infinity, P1S1
  currentData?: number[]; // charging station, infinity, P1S1
  activePowerData?: number[]; // charging station, infinity, P1S1 (zeroes)
  reactivePowerData?: number[]; // charging station, infinity, P1S1 (zeroes)
  importActiveEnergyData?: number[]; // charging station, infinity, P1S1 (zeroes)
  exportActiveEnergyData?: number[]; // charging station, infinity, P1S1 (zeroes)
  phaseVoltageData?: number[]; // charging station, infinity, P1S1
  phaseVoltageH3Data?: number[]; // charging station, infinity, P1S1 (zeroes)
  phaseVoltageH5Data?: number[]; // charging station, infinity, P1S1 (zeroes)
  lineVoltageData?: number[]; // charging station, infinity, P1S1 (zeroes)
  lineVoltageH3Data?: number[]; // charging station, infinity, P1S1 (zeroes)
  lineVoltageH5Data?: number[]; // charging station, infinity, P1S1 (zeroes)
  childData?: {[key: string]: IPowerMessage}; // locality parent
  //[key: string]: number[]|number|IFroggyDelta[]|IInputSensorDailyDeltas[]
}

export interface IVectorMessage {
  currentMagnitudeData: number[];
  currentAngleData: number[];
  voltageMagnitudeData: number[];
  voltageAngleData: number[];
}

export interface IFroggyDelta {
  sensorId: number;
  [key: string]: number;
}

export interface IHarmonicsMessage {
  phaseVoltageThd: number[];
  currentThd: number[];
  phaseVoltageHarmonics: ((number | null)[] | null)[];
  currentHarmonics: ((number | null)[] | null)[];
}
