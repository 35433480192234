import * as React from 'react';

import {Button} from '../../components/bootstrap';

import {
  OrganizationInput,
  OrganizationInputQueryState,
  useQueryableOrganizations
} from '../../components/inputs/OrganizationInput';
import {ConfigurePricingPolicyModal} from '../../components/pricingPolicies/configurePricingPolicy/ConfigurePricingPolicy';
import {SortOrder} from '../../components/Table';
import {useModals} from '../../modals/ModalContext';
import {UserRights} from '../../models/AuthUser';
import {ICardSettingsWithTable} from '../../models/CardSettings';
import {IOrganization} from '../../models/Organization';
import {IPricingPolicy} from '../../models/PricingPolicy';
import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {CardCategory, CardLocationAwareness, CardTypeKey, ICardProps, ICardType} from '../CardType';
import {CardActions} from '../components';
import {Reload} from '../components/actions';
import {Spring} from '../components/CardActions';
import {CardView, cardViewProps} from '../components/CardView';

import {PricingPolicyItem} from './PricingPolicyItem';

interface IPricingPoliciesSettings extends ICardSettingsWithTable {
  organizationId?: number;
}

interface PricingPolicyCardActionsProps {
  organizations: IOrganization[];
  currentOrganization?: IOrganization;
  onCreate: (group: IPricingPolicy) => void;
  onOrganizationSelected: (organization?: number) => void;
  onClickedRefresh: () => void;
  onUpdateQuery: (updates: Partial<OrganizationInputQueryState>) => void;
}

function PricingPolicyCardActions(props: PricingPolicyCardActionsProps) {
  const {
    organizations,
    currentOrganization,
    onOrganizationSelected: onOrganizationChange,
    onCreate,
    onClickedRefresh,
    onUpdateQuery
  } = props;
  const modals = useModals();

  const onChange = React.useCallback(
    (o?: IOrganization) => {
      onOrganizationChange(o?.id);
    },
    [onOrganizationChange]
  );

  const handleClickedCreate = () => {
    if (!currentOrganization) {
      return;
    }

    modals
      .show<IPricingPolicy | null>(props => (
        <ConfigurePricingPolicyModal organization={currentOrganization} {...props} />
      ))
      .then(created => created && onCreate(created));
  };

  return (
    <CardActions>
      <OrganizationInput
        name="organization"
        organizations={organizations}
        value={currentOrganization}
        onChange={onChange}
        onUpdateQuery={onUpdateQuery}
        placeholder={T('organizationUsers.selectOrganization')}
      />
      <Reload onReload={onClickedRefresh} />
      <Spring />
      {currentOrganization?.cpo && (
        <Button type="button" onClick={handleClickedCreate}>
          <i className="fa fa-plus mr-2" />
          {T('pricingPolicies.add')}
        </Button>
      )}
    </CardActions>
  );
}

function PricingPoliciesCard(props: ICardProps<IPricingPoliciesSettings>) {
  const {fetch, settings, updateSettings} = props;

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const [pricingPolicies, refreshPricingPolicies] = useCardLoader(
    api =>
      settings.organizationId ? api.pricingPolicies.getForOrganization(settings.organizationId) : Promise.resolve(None),
    [settings.organizationId],
    plural('pricingPolicy'),
    None
  );

  let error: string | undefined;
  if (organization === undefined) {
    error = T('errors.organization.notFound');
  } else if (organization?.cpo === undefined) {
    error = T('pricingPolicies.error.notCPO');
  } else if (pricingPolicies.length === 0) {
    error = T('pricingPolicies.noneFound');
  }

  return (
    <CardView
      error={props.loading ? undefined : error}
      actions={() => (
        <PricingPolicyCardActions
          onCreate={() => refreshPricingPolicies()}
          currentOrganization={organization}
          onOrganizationSelected={orgId => updateSettings({organizationId: orgId})}
          organizations={inputOrganizations.organizations}
          onClickedRefresh={refreshPricingPolicies}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      {...cardViewProps(props)}
    >
      {organization &&
        pricingPolicies.map(policy => (
          <PricingPolicyItem
            organization={organization}
            key={policy.id}
            policy={policy}
            onDelete={() => refreshPricingPolicies()}
            onUpdate={() => refreshPricingPolicies()}
          />
        ))}
    </CardView>
  );
}

const DEFAULT_SETTINGS: IPricingPoliciesSettings = {
  table: {
    sortColumn: 'alias',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [{name: 'alias', visible: true}]
  }
};

const CARD: ICardType<IPricingPoliciesSettings> = {
  type: CardTypeKey.PricingPolicies,
  title: 'pricingPolicies.title',
  description: 'pricingPolicies.description',
  categories: [CardCategory.ORGANIZATIONS],
  rights: UserRights.User,
  isAvailable: hasPartnerFunctionality,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: PricingPoliciesCard
};

export default CARD;
