import React, {useCallback} from 'react';

import {OrganizationInput, useQueryableOrganizations} from '../../components/inputs/OrganizationInput';
import {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {CardDisplayType} from '../../models/CardSettings';
import {ILocationSummary, locationToSummary} from '../../models/Location';
import {IOrganization} from '../../models/Organization';
import {ITableField} from '../../models/Table';
import {hasPartnerFunctionality} from '../../models/User';
import {None} from '../../utils/Arrays';
import {useOrganization} from '../../utils/FunctionalData';
import {useCardLoader} from '../../utils/Hooks';
import {plural} from '../../utils/Internationalization';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {CardActions} from '../components';
import {ExportCsv, Reload} from '../components/actions';
import {cardViewProps, ICardState} from '../components/CardView';
import {LocationsListCard} from '../Locations/LocationListCard';
import {BaseLocationsSettings} from '../Locations/LocationListCard';

interface IChargingSquaresSettings extends BaseLocationsSettings {
  organizationId?: number;
}

const ChargingSquares = (props: ICardProps<IChargingSquaresSettings>) => {
  const {fetch, settings, updateSettings} = props;

  const [inputOrganizations, updateOrganizationInputQuery] = useQueryableOrganizations();
  const organizationId = settings.organizationId || inputOrganizations.defaultOrganization?.id;
  const [organization = inputOrganizations.defaultOrganization] = useOrganization(fetch, organizationId);

  const handleChangeOrganization = useCallback(
    (org: IOrganization | undefined) => {
      updateSettings({organizationId: org ? org.id : undefined});
    },
    [updateSettings]
  );

  const [chargingSquares, refreshSquares] = useCardLoader<ILocationSummary[]>(
    api => {
      if (organization === undefined) return Promise.resolve(None);

      return api.organizations.getCPOChargingSquares(organization.id).then(squares => squares.map(locationToSummary));
    },
    [organization?.id],
    plural('location'),
    None
  );

  const refresh = refreshSquares;

  const actions = (defaultActions: React.ReactNode, columns: ITableField<ILocationSummary>[], state: ICardState) => (
    <CardActions>
      {inputOrganizations.showInput && (
        <OrganizationInput
          name="organization"
          organizations={inputOrganizations.organizations}
          value={organization}
          onChange={handleChangeOrganization}
          onUpdateQuery={updateOrganizationInputQuery}
        />
      )}
      <Reload onReload={refresh} />
      {state.ready && <ExportCsv fields={columns} settings={settings.table} items={chargingSquares} />}
    </CardActions>
  );

  return (
    <LocationsListCard
      operator={organization}
      locations={chargingSquares}
      actions={actions}
      refresh={refresh}
      updateSettings={updateSettings}
      {...cardViewProps(props)}
    />
  );
};

const defaultTableSettings: IPersistedTableSettings = {
  pageSize: 20,
  sortColumn: 'from',
  sortOrder: SortOrder.DESCENDING,
  columns: [
    {name: 'stationSerial', visible: false},
    {name: 'stationName', visible: true},
    {name: 'from', visible: true},
    {name: 'to', visible: true},
    {name: 'duration', visible: true},
    {name: 'energy', visible: true},
    {name: 'paymentType', visible: true},
    {name: 'hostCost', visible: true},
    {name: 'commissionFee', visible: true},
    {name: 'side', visible: true},
    {name: 'rfid', visible: true}
  ]
};

const CARD: ICardType<IChargingSquaresSettings> = {
  title: 'cpoChargingSquares.title',
  description: 'cpoChargingSquares.description',
  categories: [CardCategory.EV],
  rights: UserRights.User,
  type: CardTypeKey.CPOChargingSquares,
  isAvailable: hasPartnerFunctionality,
  cardClass: ChargingSquares,
  width: 4,
  height: 2,
  locationAware: CardLocationAwareness.Unaware,
  defaultSettings: {
    cardType: CardDisplayType.Table,
    table: defaultTableSettings,
    zoomLevel: 8,
    center: {lat: 50.822937, lng: 3.311227}
  }
};
export default CARD;
