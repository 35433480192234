import React, {useMemo} from 'react';

import {useAppContext} from '../../../app/context';
import {Col, Form, FormFeedback, FormGroup, Input, Label, Row} from '../../../components/bootstrap';
import {ImageInputGroup} from '../../../components/ImageInputField';
import ColorSwatch from '../../../components/inputs/ColorSwatch/ColorSwatch';
import FormInputGroup from '../../../components/inputs/FormInputGroup';
import {TextInputGroup} from '../../../components/inputs/TextInput';
import {Checkbox} from '../../../components/ui/checkbox';
import {IOrganization, OrganizationLanguage} from '../../../models/Organization';
import {FormState} from '../../../utils/FormState';
import {useRefCallback} from '../../../utils/Hooks';
import {T} from '../../../utils/Internationalization';
import {
  validateColor,
  validateEmail,
  validateRequired,
  validateSpecialChars,
  validateUrl
} from '../../../utils/Validation';
import {useUser} from '../../CardUtils';

import styles from './EditOrganizationGeneralTab.module.scss';
import {EditOrganizationFormState} from './EditOrganizationModels';
import {SearchInstallationPartner} from './SearchInstallationPartner';
import TranslationInput from './TranslationInput';

interface EditOrganizationGeneralTabProps {
  formState: FormState;
  state: EditOrganizationFormState;
  updateState: (updates: Partial<EditOrganizationFormState>) => void;
  canEditParent: boolean;
  parentOrganizations: IOrganization[];
}

export default function EditOrganizationGeneralTab(props: EditOrganizationGeneralTabProps) {
  const {formState, state, updateState, canEditParent, parentOrganizations} = props;
  const {api} = useAppContext();

  const user = useUser();

  const handleParentChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    updateState({parentId: value === 'none' ? undefined : parseInt(value)});
  };

  const parentOrganizationOptions = useMemo(() => {
    const result = parentOrganizations.map(org => (
      <option key={org.id} value={org.id}>
        {org.name}
      </option>
    ));
    result.splice(
      0,
      0,
      <option key="none" value="none">
        {T('organization.noParent')}
      </option>
    );
    return result;
  }, [parentOrganizations]);

  const handleLanguageSelected = (e: React.SyntheticEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '__') return;

    const language = e.currentTarget.value as OrganizationLanguage;
    const index = state.descriptionTranslations.findIndex(t => t.language === language);
    if (index >= 0) return;

    const newTranslations = [...state.descriptionTranslations, {language, value: ''}];
    updateState({descriptionTranslations: newTranslations});
  };

  const translations = useMemo(() => {
    const handleDescriptionTranslationChanged = (language: OrganizationLanguage, value: string) => {
      const newTranslations = state.descriptionTranslations.map(t => (t.language === language ? {language, value} : t));
      updateState({descriptionTranslations: newTranslations});
    };

    const handleDeleteLanguage = (language: OrganizationLanguage) => {
      const newTranslations = state.descriptionTranslations.filter(t => t.language !== language);
      updateState({descriptionTranslations: newTranslations});
    };

    return state.descriptionTranslations.map(translation => (
      <TranslationInput
        key={translation.language}
        translation={translation}
        onUpdate={handleDescriptionTranslationChanged}
        onDelete={handleDeleteLanguage}
        validate={validateSpecialChars}
        optional={true}
      />
    ));
  }, [state.descriptionTranslations, updateState]);

  const hasEmail = state.supportEmail.trim() !== '';
  const hasPhoneNumber = state.supportPhone.trim() !== '';
  const hasWebsite = state.supportWebsite.trim() !== '';
  const isSupportValid = !!(hasEmail || hasPhoneNumber || hasWebsite);
  if (isSupportValid && !state.isSupportValid) {
    updateState({isSupportValid: true, supportInvalidVisible: false});
  }

  const supportBlur = useRefCallback(() =>
    updateState({
      supportInvalidVisible: !isSupportValid,
      isSupportValid
    })
  );

  return (
    <Form>
      <Row>
        <Col xl={4}>
          <FormGroup>
            <Label>{T('organization.parent')}</Label>
            <Input
              type="select"
              value={state.parentId === undefined ? 'none' : state.parentId.toString()}
              onChange={handleParentChanged}
              disabled={!canEditParent}
              name="parent"
              className="!tw-w-full"
            >
              {parentOrganizationOptions}
            </Input>
          </FormGroup>
          <TextInputGroup
            form={formState}
            name="name"
            label={T('organizations.field.name')}
            value={state.name}
            onChange={name => updateState({name})}
            validate={validateRequired}
            error={formState.getServerError('name')}
          />
          {user.isServiceDesk() && (
            <FormGroup>
              <Checkbox
                id="internal"
                name="internal"
                label={T('organizations.field.internal')}
                defaultChecked={state.internal}
                onCheckedChange={internal => updateState({internal})}
                testId="internal"
              />
            </FormGroup>
          )}
          <TextInputGroup
            form={formState}
            name="website"
            label={T('organizations.field.website')}
            value={state.website}
            onChange={website => updateState({website})}
            validate={validateUrl}
            optional
          />
          <TextInputGroup
            form={formState}
            name="privacyPolicy"
            label={T('organizations.field.privacyPolicy')}
            value={state.privacyPolicyUrl}
            onChange={privacyPolicyUrl => updateState({privacyPolicyUrl})}
            validate={validateUrl}
            optional
          />
          <TextInputGroup
            type="textarea"
            name="description"
            label={`${T('organizations.field.description')} (${user?.language})`}
            value={state.description}
            onChange={description => updateState({description})}
            optional
            validate={validateSpecialChars}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginTop: -27
            }}
          >
            <Input type="select" value="__" onChange={handleLanguageSelected} style={{width: 180, marginTop: 20}}>
              <option value="__">{T('organizations.addLanguage')}</option>
              <option value={OrganizationLanguage.English}>{T('language.en')}</option>
              <option value={OrganizationLanguage.Dutch}>{T('language.nl')}</option>
              <option value={OrganizationLanguage.French}>{T('language.fr')}</option>
              <option value={OrganizationLanguage.German}>{T('language.de')}</option>
              <option value={OrganizationLanguage.Italian}>{T('language.it')}</option>
              <option value={OrganizationLanguage.Spanish}>{T('language.es')}</option>
              <option value={OrganizationLanguage.Portuguese}>{T('language.pt')}</option>
              <option value={OrganizationLanguage.Turkish}>{T('language.tr')}</option>
            </Input>
          </div>
          {translations}
        </Col>
        <Col md={4}>
          <label className={styles.colorInputLabel}>{T('organizations.field.backgroundColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="background"
              value={state.backgroundColor}
              onChange={backgroundColor => updateState({backgroundColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.backgroundColor} onChange={backgroundColor => updateState({backgroundColor})} />
          </div>
          <label className={styles.colorInputLabel}>{T('organizations.field.fontColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="fontColor"
              value={state.fontColor}
              onChange={fontColor => updateState({fontColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.fontColor} onChange={fontColor => updateState({fontColor})} />
          </div>
          <label className={styles.colorInputLabel}>{T('organizations.field.buttonBackgroundColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="buttonBackground"
              value={state.buttonBackgroundColor}
              onChange={buttonBackgroundColor => updateState({buttonBackgroundColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch
              value={state.buttonBackgroundColor}
              onChange={buttonBackgroundColor => updateState({buttonBackgroundColor})}
            />
          </div>
          <label className={styles.colorInputLabel}>{T('organizations.field.buttonFontColor')}</label>
          <div className={styles.colorInputRow}>
            <TextInputGroup
              form={formState}
              name="buttonFontColor"
              value={state.buttonFontColor}
              onChange={buttonFontColor => updateState({buttonFontColor})}
              validate={validateColor}
              optional
            />
            <ColorSwatch value={state.buttonFontColor} onChange={buttonFontColor => updateState({buttonFontColor})} />
          </div>
          <ImageInputGroup
            label={T('organizations.field.logo')}
            api={api}
            value={state.logo}
            onChange={logo => updateState({logo})}
            optional
          />
        </Col>
        <Col md={4}>
          <label className={styles.inputLabel}>{T('organizations.supportInfo')}</label>
          <TextInputGroup
            form={formState}
            name="supportEmail"
            label={T('organizations.field.supportEmail')}
            value={state.supportEmail}
            onChange={supportEmail => updateState({supportEmail})}
            validate={validateEmail}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          <TextInputGroup
            form={formState}
            name="supportPhone"
            label={T('organizations.field.supportPhone')}
            value={state.supportPhone}
            onChange={supportPhone => updateState({supportPhone})}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          <TextInputGroup
            form={formState}
            name="supportWebsite"
            label={T('organizations.field.supportWebsite')}
            value={state.supportWebsite}
            onChange={supportWebsite => updateState({supportWebsite})}
            optional
            invalid={state.supportInvalidVisible}
            onBlur={supportBlur}
          />
          {state.supportInvalidVisible && (
            <div>
              <FormFeedback>{T('organizations.supportError')}</FormFeedback>
            </div>
          )}
          {user.isServiceDesk() && (
            <FormInputGroup name="installerPartner" label={T('organizations.field.installerPartner')}>
              <SearchInstallationPartner
                name="installationPartner"
                value={state.installationPartner}
                onChange={installationPartner => updateState({installationPartner})}
                placeholder="Select partner"
              />
            </FormInputGroup>
          )}
        </Col>
      </Row>
    </Form>
  );
}
