import {SeriesAreaOptions, SeriesLineOptions} from 'highcharts/highstock';
import React, {useMemo, useCallback} from 'react';

import {createIntervalChart} from '../../components/Chart';

import HighStockChart from '../../components/HighStockChart';

import {ActivePeriod} from '../../components/PeriodSelector';
import {ChargingStation} from '../../models/ChargingStation';
import {IConsumptionValue, getConsumptionChannelCurrent} from '../../models/ConsumptionValue';
import {getPhasesForType, IHighLevelConfiguration} from '../../models/HighLevelConfiguration';
import {LoadType} from '../../models/Load';
import {getPhaseIndex, getPhaseLabel, Phase} from '../../models/Phase';
import {BrandColors} from '../../utils/BrandColors';
import {createPointFormatter} from '../../utils/GraphUtils';
import {T} from '../../utils/Internationalization';
import {sumOfSeries} from '../../utils/Series';

import {createChargingStationCurrentSeries} from './ChargingStationGraph';
import {ChargingStationConsumptionMode} from './Settings';

export interface IChargingStationConsumption {
  station?: ChargingStation;
  consumption: IConsumptionValue[];
}

interface ChargingGroupGraphProps {
  mode: ChargingStationConsumptionMode;
  maximumLoad?: number;
  stations: IChargingStationConsumption[];
  period: ActivePeriod;
  parentConsumption: IConsumptionValue[];
  highLevelConfig: IHighLevelConfiguration;
}

function aggregateChargingCurrents(phases: Phase[], stations: IChargingStationConsumption[]) {
  const result: Map<number, number[]> = new Map();
  for (var station of stations) {
    if (!station.station) continue;

    for (var side of station.station.getControllers()) {
      if (!side.smartDevice || !side.smartDevice.carCharger) continue;

      const channelIndices = side.smartDevice.carCharger.channelIndices;
      for (var consumption of station.consumption) {
        const value = result.get(consumption.timestamp) || phases.map(x => 0);
        const current = consumption.current || [];
        for (var i = 0; i < channelIndices.length; i++) {
          const channelIndex = channelIndices[i];
          if (channelIndex === null) continue;
          const currentForPhase = current[channelIndex] || 0;
          value[i] += currentForPhase;
        }
        result.set(consumption.timestamp, value);
      }
    }
  }
  return result;
}

export const ChargingGroupCurrentGraph = (props: ChargingGroupGraphProps) => {
  const {mode, maximumLoad, stations, period, parentConsumption, highLevelConfig} = props;

  const phases = useMemo(() => getPhasesForType(highLevelConfig.phaseType), [highLevelConfig.phaseType]);
  const chargerCurrentsByTimestamp: Map<number, number[]> = useMemo(() => {
    return aggregateChargingCurrents(phases, stations);
  }, [phases, stations]);

  const [consumptionChannels, productionChannels] = useMemo(() => {
    const consumption: number[][] = [[], [], []];
    const production: number[][] = [[], [], []];

    highLevelConfig.measurements.forEach(load => {
      if (load.type === LoadType.Grid) {
        load.actuals.forEach(channel => {
          consumption[getPhaseIndex(channel.phase)].push(channel.publishIndex);
        });
      } else if (load.type === LoadType.Production) {
        load.actuals.forEach(channel => {
          production[getPhaseIndex(channel.phase)].push(channel.publishIndex);
        });
      }
    });
    return [consumption, production];
  }, [highLevelConfig]);

  const getConsumptionCurrent = useCallback(
    (item: IConsumptionValue, phase: Phase) => {
      return getConsumptionChannelCurrent(item, consumptionChannels[getPhaseIndex(phase)] || []) || 0;
    },
    [consumptionChannels]
  );

  const getProductionCurrent = useCallback(
    (item: IConsumptionValue, phase: Phase) => {
      return getConsumptionChannelCurrent(item, productionChannels[getPhaseIndex(phase)] || []) || 0;
    },
    [productionChannels]
  );

  const getChargingCurrent = useCallback(
    (item: IConsumptionValue, phase: Phase) => {
      return (chargerCurrentsByTimestamp.get(item.timestamp) || [0, 0, 0])[getPhaseIndex(phase)];
    },
    [chargerCurrentsByTimestamp]
  );

  // const currentSerieIds: string[] = [];

  const [series, totalCurrentSerie] = useMemo(() => {
    const series: (SeriesAreaOptions | SeriesLineOptions)[] = [];
    const currentSerieIds: string[] = [];

    if (mode !== ChargingStationConsumptionMode.Regular) {
      for (var phase_ of phases) {
        const phase = phase_;
        const label = `${T('chargingStationConsumption.solar')} ${getPhaseLabel(phase)}`;
        series.push({
          id: `solar${phase}`,
          type: 'line',
          data: parentConsumption.map(value => [value.timestamp, getProductionCurrent(value, phase)]),
          name: `${label} [A]`,
          color: BrandColors.GreenAtlantis, // Atlantis
          yAxis: 0,
          tooltip: {pointFormatter: createPointFormatter(label, 'A', 1)}
        });
      }
    }

    stations.forEach(station => {
      const chargingStation = station.station;
      if (!chargingStation) return;

      const ids = createChargingStationCurrentSeries(
        series,
        chargingStation,
        station.consumption,
        chargingStation.data.name,
        phases,
        period.interval
      );
      for (var id of ids) currentSerieIds.push(id);
    });

    const referenceStation = stations.find(station => station.consumption.length > 0);
    const timeReference = (referenceStation && referenceStation.consumption) || parentConsumption;

    if (mode === ChargingStationConsumptionMode.OverloadIncludingConsumption && maximumLoad !== undefined) {
      series.push({
        id: 'limit',
        type: 'line',
        data: timeReference.map(value => [value.timestamp, maximumLoad]),
        name: `${T('chargingStationConsumption.currentLimit')} [A]`,
        color: BrandColors.Red,
        yAxis: 0,
        tooltip: {
          pointFormatter: createPointFormatter(T('chargingStationConsumption.currentLimit'), 'A', 1)
        }
      });
    }

    for (var phase of phases) {
      if (mode === ChargingStationConsumptionMode.OverloadIncludingConsumption) {
        const consumption = parentConsumption.map(value => [value.timestamp, getConsumptionCurrent(value, phase)]);
        const label = `${T('chargingStationConsumption.grid')} ${getPhaseLabel(phase)}`;
        series.push({
          id: `consumption${phase}`,
          type: 'area',
          data: consumption,
          name: `${label} [A]`,
          color: BrandColors.OrangeSunglow,
          yAxis: 0,
          tooltip: {pointFormatter: createPointFormatter(label, 'A', 1)}
        });
      } else {
        const consumption = parentConsumption.map(value => [
          value.timestamp,
          getConsumptionCurrent(value, phase) - getChargingCurrent(value, phase)
        ]);
        const label = `${T('chargingStationConsumption.currentLimit')} ${getPhaseLabel(phase)}`;
        series.push({
          id: `limit${phase}`,
          type: 'line',
          data: consumption.map(value => [value[0], (maximumLoad || 0) - value[1]]),
          name: `${label} [A]`,
          color: BrandColors.Red,
          yAxis: 0,
          tooltip: {pointFormatter: createPointFormatter(label, 'A', 1)}
        });
      }
    }

    const totalCurrentSerie = sumOfSeries(
      series
        .filter(x => x.id && currentSerieIds.includes(x.id) && x.data && x.data.length > 0)
        .map(x => x.data as [number, number][])
    );
    return [series, totalCurrentSerie];
  }, [
    mode,
    stations,
    parentConsumption,
    maximumLoad,
    phases,
    getProductionCurrent,
    period.interval,
    getConsumptionCurrent,
    getChargingCurrent
  ]);

  const [config, actualFrom, actualTo] = createIntervalChart({
    period,
    series,
    yAxis: [
      {
        title: {text: 'A'},
        opposite: false,
        showEmpty: false,
        startOnTick: false
      }
    ],
    navigatorSerie: {
      type: 'areaspline',
      data: totalCurrentSerie
    }
  });

  return <HighStockChart from={actualFrom} to={actualTo} config={config} />;
};
