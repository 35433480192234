import {TimedInput} from '../components/pricingPolicies/configurePricingPolicy/FormState';

import {T} from './Internationalization';

export type FieldValidator = (value: string, label: string, optional: boolean) => string | undefined;

export function validateRequired(value: string, label: string, optional: boolean) {
  const isPassword = label?.toLowerCase()?.includes('password');
  if (!optional) {
    if (value.trim().length === 0) {
      return T('validator.required', {name: label});
    } else {
      const isUnsafeInput = validateSpecialChars(value, label);
      if (!isUnsafeInput || isPassword) {
        return undefined;
      } else {
        return isUnsafeInput;
      }
    }
  }
  return undefined;
}

export function validateUsername(value: string, label: string, optional: boolean) {
  if (value.length === 0 && !optional) return T('validator.required', {name: label});
  const isUnsafeInput = validateSpecialChars(value, label);
  if (isUnsafeInput) {
    return isUnsafeInput;
  }
  return /^(?=.{2,}$)(?!.*[_.\-@]{2})[a-zA-Z0-9._\-@+]+$/.test(value) ? undefined : T('validator.invalidUsername');
}

export function validateTimedComponent(value: string, label: string, optional: boolean) {
  if (value.length === 0 && !optional) return T('validator.required', {name: label});

  const timedInput: TimedInput[] = value ? JSON.parse(value) : [];

  // must be sorted by timedInput.afterMinutes
  for (let i = 0; i < timedInput.length; i++) {
    if (i > 0 && timedInput[i].afterMinutes <= timedInput[i - 1].afterMinutes) {
      return T('validator.timed.wrongOrder');
    }
  }

  return undefined;
}

export function validateEmail(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  }
  const isUnsafeInput = validateSpecialChars(value, label);
  if (isUnsafeInput) {
    return isUnsafeInput;
  }

  return /^[^@]+@[^.]+\..+$/.test(value) ? undefined : T('validator.invalidEmail');
}

export function validateAtLeast(count: number): FieldValidator {
  return (value: string, label: string) => {
    return value?.length < count ? T('validator.atLeast', {name: label, chars: count.toString()}) : undefined;
  };
}

export function validateAtMost(count: number): FieldValidator {
  return (value: string, label: string, optional: boolean) => {
    if (value.length === 0) {
      return optional ? undefined : T('validator.required', {name: label});
    }
    return value.length > count ? T('validator.atMost', {name: label, chars: count.toString()}) : undefined;
  };
}

export const validateUrlRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

export function validateUrl(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  } else if (value.startsWith('http://') || value.startsWith('https://')) {
    if (validateUrlRegex.test(value)) return undefined;

    return T('validator.invalidUrlAlternative');
  } else return T('validator.invalidUrl', {name: label});
}

export function validateColor(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  } else if (/^#[0-9a-fA-F]{6}$/.test(value)) return undefined;
  else return T('validator.invalidColor');
}

export function validateNumber(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  } else if (/^-?[0-9]+(\.[0-9]+)?$/.test(value)) return undefined;
  else return T('validator.invalidNumber', {name: label});
}

export function validateInteger(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  } else if (/^-?[0-9]+$/.test(value)) return undefined;
  else return T('validator.invalidInteger', {name: label});
}

export function validateMatching(regex: RegExp, error: string): FieldValidator {
  return (value: string, label: string, optional: boolean) => {
    if (value.length === 0) {
      return optional ? undefined : T('validator.required', {name: label});
    }

    return regex.test(value) ? undefined : error;
  };
}

export function validateTime(value: string, label: string, optional: boolean) {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  } else if (!/^[0-9]{1,2}:[0-9]{2}$/.test(value)) {
    return T('validator.invalidTime');
  }

  const [hourString, minutesString] = value.split(':');
  const hour = parseInt(hourString);
  const minutes = parseInt(minutesString);
  if (hour >= 24 || minutes >= 60) return T('validator.invalidTime');

  return undefined;
}

const validRFIDLengths = [8, 14, 20];
export function validateRFID(value: string, label: string, optional: boolean): string | undefined {
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  }
  if (value.length > 64) return T('validator.atMost');
  if (value.startsWith('V')) {
    // virtual card
    return validateMatching(/^V[0-9A-F]{12}$/, T('validator.invalidRFID'))(value, label, optional);
  }
  if (!validRFIDLengths.includes(value.length)) {
    return T('validator.invalidRFID');
  }

  return validateMatching(/^[0-9A-Fa-f:]+$/, T('validator.invalidRFID'))(value, label, optional);
}

// format as read from https://www.theswiftcodes.com/swift-code-checker/

export function validateBIC(value: string, label = '', optional = false): string | undefined {
  const hasSpaces = /\s/g.test(value);
  if (hasSpaces) return T('validator.invalidBIC.hasSpaces');
  const sanitized = value.toUpperCase();
  const invalid = validateMatching(/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/, T('validator.invalidBIC'))(
    sanitized,
    label,
    optional
  );
  if (invalid !== undefined || value.length === 0) {
    return invalid;
  }
  return undefined;
}

export function validateIBAN(value: string, label: string, optional: boolean) {
  const sanitized = value.replaceAll(' ', '').toUpperCase();
  const invalid = validateMatching(/^[A-Z]{2}[0-9]{2}[0-9A-Z]{2,30}$/, T('validator.invalidIBAN'))(
    sanitized,
    label,
    optional
  );
  if (invalid !== undefined || value.length === 0) return invalid;

  const checksumData = `${sanitized.substring(4) + sanitized.substring(0, 2)}00`;
  const asNumber = checksumData
    .split('')
    .map(digit => (digit <= '9' ? digit : 10 + digit.charCodeAt(0) - 65))
    .join('');
  const checksum = 98 - largeModulo(asNumber, 97);
  if (checksum !== parseInt(sanitized.substring(2, 4))) {
    return T('validator.invalidIBAN.checksum');
  }

  return undefined;
}

// https://stackoverflow.com/questions/929910/modulo-in-javascript-large-number
function largeModulo(divident: string, divisor: number) {
  var partLength = 10;

  while (divident.length > partLength) {
    var part = divident.substring(0, partLength);
    divident = (parseInt(part) % divisor) + divident.substring(partLength);
  }

  return parseInt(divident) % divisor;
}

export function validateOrderNr(value: string, label: string, optional: boolean) {
  value = value.trim();
  if (value.length === 0) {
    return optional ? undefined : T('validator.required', {name: label});
  }
  if (value.toLocaleLowerCase() === 'unknown') return undefined;
  if (value.match(/ZD[0-9]{5,6}/)) return undefined;
  if (!value.startsWith('S')) return T('shipment.orderNr.invalid');

  return undefined;
}

export const validateSpecialChars = (value: string, label: string): string | undefined => {
  // Test for the following special characters:
  // <  &lt;
  // >  &gt;

  value = value.trim();
  const hasUnsafeChars = /<[^/>]+>|<|>/g;
  if (hasUnsafeChars.test(value)) {
    return T('validator.unsafeChars', {name: label});
  } else {
    return undefined;
  }
};
