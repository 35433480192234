import React, {useCallback, useMemo, useState} from 'react';

import {useAppContext} from '../../app/context';
import {Input} from '../../components/bootstrap';

import PeriodSelector, {
  Period,
  PeriodRoundingMode,
  PeriodSettings,
  usePeriodRangeForTimezone
} from '../../components/PeriodSelector';
import {SplitPane} from '../../components/SplitPane';
import Table, {IPersistedTableSettings, SortOrder} from '../../components/Table';
import {UserRights} from '../../models/AuthUser';
import {ICardSettings} from '../../models/CardSettings';
import {IChargingSession} from '../../models/ChargingStation';
import {ITableField} from '../../models/Table';
import {Interval} from '../../models/UsageValue';
import {IUser} from '../../models/User';
import {AppFeature, hasFeature} from '../../utils/AppParameters';
import {None} from '../../utils/Arrays';
import {useAutoRefresh, useCardLoader, useDelayedEffect} from '../../utils/Hooks';
import {plural, T} from '../../utils/Internationalization';
import {testingClasses} from '../../utils/TestingClasses';
import {ICardType, CardCategory, CardTypeKey, CardLocationAwareness, ICardProps} from '../CardType';
import {useUser} from '../CardUtils';
import {getTableColumns as getSessionColumns} from '../ChargingSessions/Columns';
import {ExportCsv, Reload} from '../components/actions';
import {CardActions} from '../components/CardActions';
import {CustomSettings, CardView, CustomActions} from '../components/CardView';
import ColumnChooser from '../components/ColumnChooser';
import {getTableColumns as getUserColumns} from '../Users/Columns';

import {useUsersState} from './State';

const userRowKey = (item: IUser) => item.id;
const sessionRowKey = (item: IChargingSession) => item.id;

interface IUserSessionsSettings extends ICardSettings {
  userTable: IPersistedTableSettings;
  sessionsTable: IPersistedTableSettings;
  period: PeriodSettings;
}

function UserChargingSessionsCard(props: ICardProps<IUserSessionsSettings>) {
  const {settings, updateSettings} = props;

  const {api} = useAppContext();
  const me = useUser();
  const isServiceDesk = me.isServiceDesk();

  const [state, actor] = useUsersState();
  const {query, items, notYetLoaded} = state;
  const [user, setUser] = useState<IUser>();

  const activePeriod = usePeriodRangeForTimezone(settings.period, 'Europe/Brussels', PeriodRoundingMode.INCLUSIVE);
  const [sessions, refreshSessions] = useCardLoader<IChargingSession[]>(
    api =>
      user && activePeriod
        ? api.user.getUserChargingSessions(user.id, activePeriod.from, activePeriod.to)
        : Promise.resolve([]),
    [user?.id, activePeriod],
    plural('chargingSession'),
    None
  );

  const refresh = useCallback(() => {
    if (isServiceDesk && query === '') {
      actor.reset();
      return;
    }

    actor.startLoading();
    refreshSessions();
    api
      .findUsers(query)
      .then(items => actor.finishLoading(items))
      .catch(() => actor.errorLoading('users'));
  }, [api, actor, query, isServiceDesk, refreshSessions]);
  useDelayedEffect(refresh, [refresh], 500);
  useAutoRefresh(refresh);

  const userColumns: ITableField<IUser>[] = useMemo(() => getUserColumns(me), [me]);
  const sessionColumns: ITableField<IChargingSession>[] = useMemo(() => getSessionColumns([], true), []);
  const customSettings: CustomSettings<IUserSessionsSettings> = (editingSettings, updateEditingSettings) => {
    return (
      <>
        <ColumnChooser
          fields={userColumns}
          settings={editingSettings.userTable}
          updateSettings={userTable => updateEditingSettings({userTable})}
        />
        <ColumnChooser
          fields={sessionColumns}
          settings={editingSettings.sessionsTable}
          updateSettings={sessionsTable => updateEditingSettings({sessionsTable})}
        />
      </>
    );
  };

  const handleChangedSearch = useCallback(
    (e: React.SyntheticEvent<HTMLInputElement>) => actor.updateQuery(e.currentTarget.value),
    [actor]
  );

  const actions: CustomActions = state => (
    <CardActions>
      <Reload onReload={refresh} />
      <Input
        name="query"
        type="text"
        autoWidth={true}
        placeholder={T('users.search')}
        onChange={handleChangedSearch}
        value={query}
        className={testingClasses.searchUsers}
        style={{marginRight: 10}}
        data-testid={testingClasses.searchUsers}
      />
      <PeriodSelector
        settings={settings.period}
        onChanged={period => updateSettings({period})}
        withoutInterval={true}
      />
      <ExportCsv
        fields={sessionColumns}
        settings={settings.sessionsTable}
        items={sessions}
        name="sessions"
        range={settings.period}
      />
    </CardActions>
  );

  const error = notYetLoaded ? T('userChargingSessions.error.notYetLoaded') : undefined;

  return (
    <CardView
      ready={!state.waiting}
      error={error}
      actions={actions}
      customSettings={customSettings}
      settings={settings}
      loading={props.loading}
      fetching={[...props.fetching, {name: 'users', loading: state.loading, error: state.error}]}
    >
      <SplitPane>
        <Table
          fields={userColumns}
          items={items}
          rowKey={userRowKey}
          hasPaging={true}
          noun="user"
          settings={settings.userTable}
          updateSettings={userTable => updateSettings({userTable})}
          onClickedRow={setUser}
        />
        <Table
          fields={sessionColumns}
          items={sessions}
          rowKey={sessionRowKey}
          hasPaging={true}
          noun="chargingSession"
          settings={settings.sessionsTable}
          updateSettings={sessionsTable => updateSettings({sessionsTable})}
        />
      </SplitPane>
    </CardView>
  );
}

const DEFAULT_SETTINGS: IUserSessionsSettings = {
  userTable: {
    sortColumn: hasFeature(AppFeature.SocialLogin) ? 'emailAddress' : 'userName',
    sortOrder: SortOrder.ASCENDING,
    pageSize: 10,
    columns: [
      {name: 'firstName', visible: true},
      {name: 'emailAddress', visible: true},
      {name: 'role', visible: true}
    ]
  },
  sessionsTable: {
    pageSize: 20,
    sortColumn: 'from',
    sortOrder: SortOrder.DESCENDING,
    columns: [
      {name: 'from', visible: true},
      {name: 'to', visible: true},
      {name: 'duration', visible: true},
      {name: 'energy', visible: true},
      {name: 'paymentType', visible: true},
      {name: 'cost', visible: true},
      {name: 'rfid', visible: true},
      ...(hasFeature(AppFeature.SocialLogin) ? [] : [{name: 'userName', visible: true}])
    ]
  },
  period: {
    interval: Interval.DAY, // unused
    period: Period.DAYS_7
  }
};
const CARD: ICardType<IUserSessionsSettings> = {
  type: CardTypeKey.UserChargingSessions,
  title: 'userChargingSessions.title',
  description: 'userChargingSessions.description',
  categories: [CardCategory.EV],
  rights: UserRights.ServiceDesk,
  width: 4,
  height: 2,
  defaultSettings: DEFAULT_SETTINGS,
  locationAware: CardLocationAwareness.Unaware,
  cardClass: UserChargingSessionsCard
};
export default CARD;
